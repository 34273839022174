import React from "react";
import {selectEventConfig} from "../../event_fetcher/EventFetcherSlice";
import {useSelector} from "react-redux";
import logo_stocznia from "../../../assets/images/stocznia/logo_stocznia.png";
import logo_unum from "../../../assets/images/stocznia/unum.png";
import {Link} from "react-router-dom";
const PaymentInfo = ({coupon, partner}) => {
  console.log(partner)
  const {event} = useSelector(selectEventConfig);
  return (
    <div className = "">
      <div className = "payment-header bg-green-100">
        <div className = "center-container narrow-2 mb-10 py-6 md:py-24">
          <h2 className = "bigger">Krok 3:</h2>
          <h2 className = "text-3xl text-green-light mt-0 md:mb-4 md:mb-6">Pomóż nam pomagać <br /> osobom
            starszym <br />
            <strong> - przekaż darowiznę </strong></h2>
        </div>
      </div>
      <div className = "center-container narrow-2 mb-6 md:mb-12">
        {(coupon && coupon.free) || (partner && partner.free) ? (
          <div className = "mt-10 text-xl">
            <p className = "mb-5"><strong>Twój prezent niebawem trafi do bliskiej Ci osoby!</strong></p>

            <p className = "mb-5"><strong>Serdecznie dziękujemy wszystkim pracownikom Unum Życie TUiR S.A. oraz Fundacji
              Unum za udział w akcji z okazji Dnia Babci i Dziadka!</strong></p>
            <p className = "mb-5">Dzięki Waszemu wsparciu jesteśmy w stanie tworzyć kolejne materiały do treningów
              umysłu i wspierać osoby starsze.</p>
            <p className = "mb-5">Zapraszamy do korzystania ze strony <a href = "https://silowniapamieci.pl"
              target = "_blank" rel = "noreferrer">www.silowniapamieci.pl</a> - znajduje się tu wiele materiałów i
              narzędzi do treningów
              funkcji poznawczych dla osób starszych.</p>
            <p className = "mb-5"><strong>Wspólnie wspieramy osoby starsze!</strong></p>
            <div className = "flex justify-center w-full md:w-1/2 mx-auto items-center gap-5">

              <div className="w-1/2"s><img src={logo_unum}  alt="logo unum"/></div>
              <div className="w-1/2"><img src={logo_stocznia}  alt="logo siłownia" /></div>
            </div>
            <div className="flex justify-center mt-6">

              <a href = {`/partner/${partner.code}/r`} className = "yellow-button">
                Wyślij kolejny <strong>prezent</strong>
              </a>
            </div>
          </div>
        ) : (
          <>
            <p className = "mb-5 md:mb-6 text-base md:text-xl mt-4 md:mt-12">
              Twój prezent jest już gotowy do wysłania. Upominek i serdeczne życzenia z okazji
              Dnia Babci i Dziadka z pewnością sprawią przyjemność i miłą niespodziankę Twoim bliskim.
            </p>
            <p className = "mb-5 md:mb-6 text-base md:text-xl">
              Prosimy Cię o przekazanie darowizny. Sugerujemy przekazanie 25 zł lub więcej. Ta kwota pozwoli nam pokryć
              koszt przygotowania, wydruku i przesyłki prezentu, ale przede wszystkim pomoże nam kontynuować program
              Siłownia Pamięci i wspierać seniorów w trosce o ich zdrowie, sprawną pamięć, komfort i bezpieczeństwo.
            </p>
            <p className = "text-xl md:text-3xl text-green-light font-bold">
              Dziękujemy za Twoje wsparcie!
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentInfo;
