import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import InputLabel from "./InputLabel";
import EmailField from "./EmailField";
import CheckTherms from "./CheckTherms";
import SubmitButton from "./SubmitButton";
import PhoneField from "./PhoneField";
import {changeStep, selectStep, setupContactForm} from "../letterOrderSlice";

const ContactForm = () => {
  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    unregister,
    reset,
    getValues,

    formState: {errors, touchedFields},
  } = useForm();
  const dispatch = useDispatch();
  console.log({errors});
  const step = useSelector(selectStep);
  const firstName = watch("sender.first_name");
  const lastName = watch("sender.last_name");
  const sender = watch("sender");

  useEffect(() => {
    if (!firstName) return;
    const firstNameUpper = firstName.replace(/(^\w|\s\w)/g, (s) => s.toUpperCase());
    setValue("sender.first_name", firstNameUpper);
  }, [firstName]);

  useEffect(() => {
    if (!lastName) return;
    const lastNameUpper = lastName.replace(/(^\w|\s\w)/g, (s) => s.toUpperCase());
    setValue("sender.last_name", lastNameUpper);
  }, [lastName]);

  const onSubmit = (data) => {
    console.log(data);
    dispatch(setupContactForm(data));
    // reset();
    // dispatch(setupLetter(data));
  };
  const copySendarDataToReceiver = () => {
    const seder_data = {...sender};
    delete seder_data.phone;
    delete seder_data.email;
    delete seder_data.first_name;
    delete seder_data.last_name;
    setValue("receiver", seder_data);
  };
  return (
    <>
      <div className = "center-container narrow">
        <h2 className = "bigger mt-10 mb-0 md:mb-0">Krok 2:</h2>
        <h2 className = "big mt-0 mb-4 md:mb-6 ">Wypełnij dane adresowe</h2>

        <p className = "mt-3 md:mt-8 mb-0 font-bold text-xl md:text-2xl">Twoje dane kontaktowe</p>
        <p className = "text-sm md:text-xl">Zostaw nam swoje dane - powiadomimy Cię o wysyłce prezentu i będziemy
          dzielić
          się<br /> informacjami o nowych materiałach do treningu umysłu dale seniorów
        </p>
      </div>

      <form onSubmit = {handleSubmit(onSubmit)}>
        <div className = "border-bottom-with-shadow pb-12 md:mt-8">
          <div className = "center-container narrow">
            <div className = "md:grid grid-cols-2 md:gap-6 md:pr-10">
              <InputLabel
                name = "sender.first_name"
                label = "Imię"
                register = {register}
                errors = {errors}
                autoComplete = "given-name"
                required = {true}
              />
              <InputLabel
                name = "sender.last_name"
                label = "Nazwisko"
                register = {register}
                errors = {errors}
                autoComplete = "family-name"
                required = {true}
              />
            </div>

            <div className = "md:grid grid-cols-2 md:mt-6 md:gap-6 md:pr-10">
              <EmailField
                register = {register}
                errors = {errors}
                name = "sender.email"
                label = "E-mail"
                autoComplete = "email"
              />
              <InputLabel
                name = "sender.phone"
                label = "Telefon"
                placeholder = "000000000"
                register = {register}
                errors = {errors}
                autoComplete = "phone"
                required = {false}
                pattern = {/[0-9]{9}/}
                error_message="Wpisz telefon w formacie 9 cyfrowym 000000000"
              />
            </div>
          </div>
        </div>
        <div className = "">
          <div className = "center-container narrow">
            <p className = "mt-3 md:mt-8 mb-0 font-bold text-xl md:text-2xl">Dane do wysyłki prezentu</p>
            <p className = "text-sm md:text-xl">Podaj dane osoby, której chcesz wysłać prezent.
              Dane te wykorzystamy wyłącznie do wysłania prezentu, potem je usuniemy.
              Jeśli wolisz przekazać prezent osobiście, możesz też podać swój adres.
            </p>
            <div className = "md:grid grid-cols-2 md:gap-6 md:pr-10 md:mt-8">
              <InputLabel
                name = "receiver.first_name"
                label = "Imię"
                register = {register}
                errors = {errors}
                autoComplete = "given-name"
                required = {true}
              />
              <InputLabel
                name = "receiver.last_name"
                label = "Nazwisko"
                register = {register}
                errors = {errors}
                autoComplete = "family-name"
                required = {true}
              />
            </div>
            <div className = "md:grid grid-cols-2 md:gap-6 md:pr-10 md:mt-8">
              <InputLabel
                name = "receiver.street_address"
                label = "Ulica"
                register = {register}
                errors = {errors}
                required = {true}
              />
              <div className = "flex gap-6">
                <InputLabel
                  name = "receiver.street_number"
                  label = "Numer domu"
                  register = {register}
                  errors = {errors}
                  required = {true}
                  otherClasses = "contact-input-sm"
                />
                <InputLabel
                  name = "receiver.apartment_number"
                  label = "Numer mieszkania"
                  register = {register}
                  errors = {errors}
                  required = {false}
                  otherClasses = "contact-input-sm"
                />
              </div>
            </div>
            <div className = "md:grid grid-cols-2 md:gap-6 md:pr-10 md:mt-8">
              <InputLabel
                name = "receiver.city"
                label = "Miasto"
                register = {register}
                errors = {errors}
                autoComplete = "address-level2"
                required = {true}
              />
              <InputLabel
                name = "receiver.postal_code"
                label = "Kod pocztowy"
                placeholder = "00-000"
                register = {register}
                errors = {errors}
                autoComplete = "postal-code"
                required = {true}
                pattern = {/[0-9]{2}-[0-9]{3}/}
                error_message="Wpisz kod pocztowy w formacie 00-000"
              />
            </div>
            <CheckTherms register = {register} errors = {errors} />
            <div className="flex mt-8 justify-center gap-6">
              <div>
                <button
                  onClick = {() => dispatch(changeStep(1))}
                  className = "yellow-button"
                >
                  <strong> Wróć</strong>
                </button>
              </div>
              <SubmitButton />
            </div>
          </div>
        </div>
      </form>

    </>
  )
    ;
};

export default ContactForm;
