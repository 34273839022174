import React from "react";
import {SubHeader} from "./SubHeader";
import {RulesHeader} from "./RulesHeader";
import {P} from "./P";

export const Rules = () =>
  <div className = "center-container narrow">
    <RulesHeader header = "Regulamin serwisu https://www.prezentdlababciidziadka.pl" update = "20 grudnia 2021 r" />
    <P>Poniżej znajdziesz regulamin serwisu internetowego https://www.prezentdlababciidziadka.pl, w którym zawarte
      zostały informacje o świadczeniu za pośrednictwem tego serwisu usług drogą elektroniczną.</P>
    <P>Serwis należy do Fundacji Stocznia (dalej: Stocznia, my) z siedzibą w Warszawie, 00-277, przy Plac Zamkowy 10,
      wpisaną do rejestru stowarzyszeń, innych organizacji społecznych i zawodowych, fundacji oraz publicznych zakładów
      opieki zdrowotnej i do rejestru przedsiębiorców prowadzonych przez Sąd Rejonowy dla m. st. Warszawy w Warszawie,
      XII Wydział Gospodarczy Krajowego Rejestru Sądowego, KRS 0000324413, NIP 7010170150, REGON 141754758.</P>
    <P>W każdej chwili możesz skontaktować się z nami, wysyłając wiadomość na adres e-mail stocznia@stocznia.org.pl</P>

    <SubHeader>§ 1. Definicje </SubHeader>
    <P>
      Na potrzeby niniejszego regulaminu, przyjmuje się następujące znaczenie poniższych pojęć:
      <ul className = "list-decimal pl-6">
        <li><strong>Administrator</strong> – Fundacji Stocznia (dalej: Stocznia, my) z siedzibą w Warszawie, 00-277,
          przy Plac Zamkowy 10,
        </li>
        <li><strong>Regulamin</strong> – niniejszy regulamin, dostępny pod adresem www.prezentdlababciidziadka.pl w
          stopce strony,
        </li>
        <li><strong>Serwis</strong> – serwis internetowy dostępny pod adresem https://www.prezentdlababciidziadka.pl,
        </li>
        <li><strong>Użytkownik</strong> – podmiot korzystający z Serwisu.</li>
      </ul>
    </P>
    <SubHeader>§ 2. Postanowienia wstępne</SubHeader>
    <P>
      <ul className = "list-decimal pl-6">
        <li>Za pośrednictwem Serwisu, Administrator świadczy na rzecz Użytkowników usługi drogą elektroniczną polegające
          na zapewnianiu Użytkownikom możliwości:
          <ul className = "list-decimal pl-6">
            <li>przeglądania zawartości Serwisu,</li>
            <li>wypełnienie formularza adresowego,</li>
            <li>wpłaty darowizny.</li>
          </ul>
        </li>

        <li>Regulamin określa zasady i warunki korzystania z Serwisu, a także prawa i obowiązki Administratora i
          Użytkownika.
        </li>
        <li>Do korzystania z Serwisu nie jest konieczne spełnienie szczególnych warunków technicznych przez komputer lub
          inne urządzenie Użytkownika. Wystarczające są: dostęp do Internetu,
          <ul className = "list-decimal pl-6">
            <li>standardowy system operacyjny,</li>
            <li>standardowa przeglądarka internetowa,</li>
            <li>posiadanie aktywnego adresu e-mail – w celu wypełnienia formularza adresowego.</li>
          </ul>
        </li>
        <li>Zakazane jest dostarczanie przez Użytkownika treści o charakterze bezprawnym, w szczególności poprzez
          przesyłanie takich treści za pośrednictwem formularzy dostępnych w Serwisie.
        </li>
        <li>Użytkownik może przeglądać zawartość Serwisu anonimowo, tj. bez konieczności podawania jakichkolwiek danych,
          ale w celu skorzystania z formularza adresowego wymagane jest podanie określonych danych osobowych. Zasady
          postępowania z danymi osobowymi zostały opisane w polityce prywatności dostępnej pod adresem
          www.prezentdlababciidziadka.pl w stopce strony.
        </li>
        <li>Korzystanie z Serwisu jest nieodpłatne.</li>
        <li>W celu zapewnienia bezpieczeństwa Użytkownikowi i przekazu danych w związku z korzystaniem z Serwisu,
          Administrator podejmuje środki techniczne i organizacyjne odpowiednie do stopnia zagrożenia bezpieczeństwa
          świadczonych usług, w szczególności środki służące zapobieganiu pozyskiwania i modyfikacji danych osobowych
          przez osoby nieuprawnione.
        </li>
        <li>Administrator podejmuje działania w celu zapewnienia w pełni poprawnego funkcjonowania Serwisu. Użytkownik
          powinien poinformować Administratora o wszelkich nieprawidłowościach lub przerwach w funkcjonowaniu Serwisu.
        </li>
      </ul>
      <SubHeader>§ 3. Zawartość Serwisu </SubHeader>
      <ul className = "list-decimal pl-6">
        <li>Zawartość Serwisu tworzą materiały tekstowe, graficzne, zdjęciowe, ewentualnie nagrania dźwiękowe, materiały
          audiowizualne.
        </li>
        <li>Zawartość Serwisu dostępna jest publicznie dla wszystkich Użytkowników.</li>
        <li>W Serwisie mogą być zawarte hiperłącza przekierowujące Użytkownika na strony zewnętrzne, w szczególności na
          strony Administratora, za pośrednictwem których możliwe jest zapoznanie się z innymi formami działalności i
          ofertą Administratora.
        </li>
        <li>Zawartość Serwisu objęta jest ochroną prawno-autorską. Prawa zastrzeżone na rzecz Fundacja Stocznia.
          Rozpowszechnianie treści dostępnych w Serwisie bez uprzedniej zgody podmiotów uprawnionych może stanowić
          naruszenie praw autorskich i skutkować odpowiedzialnością cywilną lub karną.
        </li>
        <li>Na zawartość Serwisu składa się również formularze, który pozwalają Użytkownikowi zamówić prezent:
          spersonalizowany list oraz zeszyt ćwiczeń oraz dokonać wpłaty darowizny na cele statutowe Administratora.
        </li>
      </ul>
      <SubHeader>§ 4. Zgłoszenie problemów </SubHeader>
      <ul className = "list-decimal pl-6">
        <li>Problemy z funkcjonowaniem strony mogą być zgłaszane za pośrednictwem poczty elektronicznej na adres
          stocznia@stocznia.org.pl.
        </li>
        <li>Zgłoszenie powinno zawierać oznaczenie Użytkownika oraz opisywać rodzaj problemu i okoliczności jego
          wystąpienia, a także ewentualnie żądanie Użytkownika w związku z jego wystąpieniem.
        </li>
        <li>Zgłoszenia będą rozpatrywane na bieżąco przez Administratora, lecz w terminie nie dłuższym niż 14 dni od
          jego doręczenia. Administrator o swojej decyzji zawiadomi Użytkownika w ten sam sposób, w jaki wysłano mu
          zgłoszenie. Administrator zastrzega sobie możliwość pozostawienia zgłoszenia bez odpowiedzi, jeśli dotyczy ona
          dysfunkcji wynikających z nieznajomości lub nieprzestrzegania regulaminu.
        </li>
      </ul>
      <SubHeader>§ 5. Dane osobowe i pliki cookies </SubHeader>
      <ul className = "list-decimal pl-6">
        <li>Administratorem danych osobowych Użytkownika jest Administrator.</li>
        <li>Dane osobowe przetwarzane są w celu świadczenia usług drogą elektroniczną.</li>
        <li>Zasady postępowania z danymi osobowymi oraz wykorzystywania plików cookies w ramach Serwisu opisane są w
          polityce prywatności i plików cookies dostępnej pod adresem www.prezentdlababciidziadka.pl w stopce strony.
        </li>
      </ul>
      <SubHeader>§ 6. Odpowiedzialność Administratora </SubHeader>
      <ul className = "list-decimal pl-6">
        <li>Administrator dołoży wszelkich starań, aby Serwis dostępny był dla Użytkownika bez przerw i zakłóceń.</li>
        <li>Administrator nie jest jednak w stanie zagwarantować, że Serwis będzie dostępny dla Użytkownika bez przerw i
          zakłóceń, w szczególności ze względu na konieczne prace techniczne lub modernizacyjne. W związku z tym,
          Administrator zastrzega sobie prawo do przerw w dostępności Serwisu, w szczególności ze względu na konieczne
          do przeprowadzenia prace techniczne lub modernizacyjne.
        </li>
        <li>Administrator może podjąć decyzję o zakończeniu działalności w dowolnym momencie i z dowolnych przyczyn bez
          konieczności tłumaczenia tych przyczyn Użytkownikowi. W takiej sytuacji, Administrator powiadomi o swojej
          decyzji Użytkownika.
        </li>
      </ul>
      <SubHeader>§ 7. Pozostałe postanowienia </SubHeader>
      <ul className = "list-decimal pl-6">
        <li>Administrator zastrzega sobie możliwość wprowadzania zmian w Regulaminie.</li>
        <li>Niniejszy Regulamin obowiązuje od dnia 20 grudnia 2021 r.</li>
      </ul>

    </P>
  </div>
;