import React from "react";
import {Link} from "react-router-dom";

const CheckTherms = () => {
  return (

    <div className = "text-sm my-10">

      Fundacja Stocznia przetwarza dane osoby wysyłającej prezent oraz osoby, do której ma on trafić, na podstawie art.
      6 pkt. b, c i f RODO, czyli po to, by przygotować i wysłać prezent, zarejestrować darowiznę oraz informować o
      działaniach Fundacji na rzecz osób starszych. Więcej informacji o przetwarzaniu danych osobowych znajduje się


      <Link to = "/polityka-prywatnosci"
        target = "_blank"
        rel = "noreferrer"
      > tutaj.
      </Link>

    </div>

  );
};

export default CheckTherms;
