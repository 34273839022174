import React from "react";
import {
  SlideContainer,
  Author,
  SlideImg,
  CheckedImg,
} from "./BackGroundSliderStyled";
import checkedImg from "../../assets/images/check.png";
const Slide = ({
  background,
  current,
  isOnForm,
  isSelected,
  handleChangeSelectedBackground,
}) => {
  return (
    <div className ={`${isSelected ? "active" : ""} step cursor-pointer`}>
      <div className="p-4 md:p-12 flex justify-center" onClick={() => handleChangeSelectedBackground(background.id)}>
      <img
        className=""
        src={background.thumbnail_url}
        alt={background.author}
        width="176"

      />
      </div>
    </div>
  );
};

export default Slide;
