import React from "react";

const SubmitButton = ({onClick, label}) => {
  return (

      <div className="text-center">
        <button type="submit" className="yellow-button" onClick={onClick}>
          <strong>{label || 'Dalej'}</strong>
        </button>
      </div>

  );
};

export default SubmitButton;
