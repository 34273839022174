import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {selectEventConfig} from "../features/event_fetcher/EventFetcherSlice";
import {selectLetterId} from "../features/letter_order/letterOrderSlice";
import {useSelector} from "react-redux";
import './MenuTop.scss'
import logo from './../assets/images/stocznia/logo_stocznia.png'
import logo_silownia from './../assets/images/stocznia/logo_silownia.png'
import {Hamburger} from "../pages/MainPage/Hamburger";

const MenuTop = () => {
  const [hideMenu, setHideMenu] = useState(true);
  const [showTopButton, setShowTopButton] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);

  const location = useLocation();
  const {event} = useSelector(selectEventConfig);
  const letterId = useSelector(selectLetterId)
  console.log({selectLetterId})
  useEffect(() => {
    setHideMenu(true);
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/wyslij-prezent') {
      setShowTopButton(false);
    }
  }, [location]);

  const handleClick = () => {

    setMobileMenu(!mobileMenu);
  };

  return (

    <div className = "MenuTop border-bottom-with-shadow">
      {!event.active && (
        <div className = "center-container py-2">
          <ul className = "flex justify-center items-center">
            <li className = "md:pl-0 lg:pr-6 lg:pr-9">
              <img src = {logo} alt = {"fundacja stocznia"} width = "83" />
            </li>
            <li>
              <img src = {logo_silownia} alt = {"siłownia pamięci"} />
            </li>
          </ul>
        </div>
      )}
      {event.active && (
        <div className = "center-container md:flex justify-between items-center p-4 md:p-0">
          <div className = "md:pr-5 lg:pr-32 w-100">
            <ul className = "flex justify-between items-center">
              <li className = "md:pl-0 lg:pr-6 lg:pr-9">
                <img src = {logo} alt = {"fundacja stocznia"} width = "83" />
              </li>
              <li>
                <img src = {logo_silownia} alt = {"siłownia pamięci"} />
              </li>
              <li className = "md:hidden">
                <Hamburger onClick = {handleClick} />
              </li>
            </ul>
          </div>
          {!letterId && showTopButton &&
            <div className = "flex justify-center md:hidden">
              <Link to = "/wyslij-prezent" className = "yellow-button">
                Wyślij <strong>prezent</strong>
              </Link>
            </div>}
          <div className = "flex-1">
            <ul className = {`${!mobileMenu ? "hidden" : ""} md:block`}>
              <li>
                {letterId && <a href = "/">O naszym <strong>prezencie</strong></a>}
                {!letterId && <Link to = "/">O naszym <strong>prezencie</strong></Link>}

              </li>
              <li>
                <Link to = "/jak-wyslac-prezent">Jak wysłać <strong>prezent?</strong></Link>
              </li>
              <li>
                <Link to = "/jak-pomaga-twoja-darowizna"><strong>Jak pomaga</strong> Twoja darowizna?</Link>
              </li>
              {!letterId &&
                <li className = "hidden md:block">
                  <Link to = "/wyslij-prezent" className = "yellow-button">
                    Wyślij <strong>prezent</strong>
                  </Link>
                </li>}
              <li>
                <Link to = "/faq"><strong>FAQ</strong></Link>
              </li>
            </ul>
          </div>
        </div>
      )}

    </div>

  );
};

export default MenuTop;
