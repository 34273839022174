import React from "react";
import parse from "html-react-parser";

const LivePreviewContent = ({template, data}) => {
  const joinMulti = (multi, replacer) => {
    const cMulti = [...multi];

    if(cMulti.length === 0) return replacer;
    cMulti.map((item) => {
      if(!item.display) {
        item.display = replacer;
      }
      return item;
    })
    if(cMulti.length>1) {
      const lastElement = cMulti.pop();
      const stringToReturn = cMulti.map(item => item.display).join(", ");
      return stringToReturn + " oraz " + lastElement.display;
    }else{
      return cMulti.map(item => item.display).join(", ");
    }
  };
  
  const extracted  = (data, f, replacer) =>{
    if (data[f] && data[f].display && data[f].hasInsideReplacer) {
      const hastagregex = /#\S*/gm;
      const foundInsideReplacer = data[f].display.match(hastagregex);

      //wartość znalezionego pola do zamiany
      const replacerField =
        foundInsideReplacer &&
        foundInsideReplacer[0] &&
        data[foundInsideReplacer[0].substring(1)]; // without pre hash #

      replacer = data[f].display.replace(
        foundInsideReplacer[0],
        replacerField ? replacerField : ".........."
      );
    } else if (data[f] && data[f].display) {
      replacer = data[f].display;
    } else {
      if (data[f] && data[f].label) {
        if (!data[f].open) replacer = data[f].label;
      }
    }
    return replacer;
  }

  const generatePreview = (template, data) => {
    console.log({data})
    for (var f in data) {
      let replacer = "..........";
      if (typeof data[f] === "string") {
        if (data[f]) replacer = data[f];
      } else if (Array.isArray(data[f])) {
        // replacer = data[f].map(item => item.display).join(", ");
        replacer = joinMulti(data[f], replacer);
      } else {
        // szuka wewnątrz opcji czy nie ma do zamiany hasha z innego pola.
        replacer = extracted(data, f, replacer);
      }
      // console.log({ replacer });

      let stringToReplace = new RegExp("#" + f + "", "g");

      template = template.replace(/\n/g, "<br />");
      template = template.replace(stringToReplace, `<strong class="only_preview">${replacer}</strong>`);
    }
    // to jest dla pól typu select, które pojawia się dopiero po wybraniu innego pola
    template = template.replace(/#\S*/gm, `<strong class="only_preview">..........</strong>`);
    // return parse(template);
    // console.log(parse(template));
    return parse(template);
  };

  return <div>{generatePreview(template, data)}</div>;
};

export default LivePreviewContent;
