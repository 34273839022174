import React, {useEffect} from "react";
import HeaderIcons from "./HeaderIcons";
import {useDispatch, useSelector} from "react-redux";
import {checkPartnerCodeAsync, selectStep} from "./../../features/letter_order/letterOrderSlice";

import { LetterOrderFeature } from "../../features/letter_order/LetterOrderFeature";
import {useParams} from "react-router-dom";
import {selectEventConfig} from "../../features/event_fetcher/EventFetcherSlice";
const LetterOrder = () => {
  let params = useParams();

  const {event} = useSelector(selectEventConfig);
  const step = useSelector(selectStep);
  const dispatch = useDispatch();
  useEffect(() => {
    if (params.partner) {
      dispatch(
        checkPartnerCodeAsync({partner: params.partner, event: event.id})
      );
    }
  }, []);
  let icon_step = step;
  if (step > 0) {
    icon_step = step - 1;
  }
  return (
    <>
      <HeaderIcons step={icon_step} />
      <LetterOrderFeature />
    </>
  );
};

export default LetterOrder;
