import React from 'react';
import {RulesHeader} from "./RulesHeader";
import {SubHeader} from "./SubHeader";
import {P} from "./P";

const RulesDonate = () => {
  return (
    <div className = "center-container narrow">
      <RulesHeader header = "Regulamin przekazywania darowizn na stronie www.prezentdlababciidziadka.pl"
        update = "20 grudnia 2021 r." />
      <SubHeader>I. Postanowienia wstępne </SubHeader>
      <P>
        <ul className = "list-decimal pl-6">
          <li>Niniejszy regulamin, zwany dalej Regulaminem, określa zasady świadczenia przez Fundację Stocznia,
            Warszawa, 00-277 przy Placu Zamkowym 10, KRS: 0000324413, NIP: 7010170150, zwaną dalej Fundacją usługi drogą
            elektroniczną, polegającej na umożliwieniu osobom korzystającym ze strony www.prezentdlababciidziadka.pl,
            przekazywanie darowizn pieniężnych na działania statutowe Fundacji.
          </li>
          <li>Ilekroć w niniejszym Regulaminie jest mowa o Darczyńcy, należy przez to rozumieć każdą osobę korzystającą
            ze stronywww.prezentdlababciidziadka.pl w celu przekazania darowizny.
          </li>
          <li>Darczyńca oświadcza i potwierdza, że zapoznał się z treścią Regulaminu i akceptuje jego postanowienia.
          </li>
          <li>Aktualna wersja Regulaminu jest zawsze dostępna dla Darczyńców na stronie
            www.prezentdlababciidziadka.pl.
          </li>
        </ul>
      </P>
      <SubHeader>II. Zasady przekazywania darowizn na stronie www </SubHeader>
      <P>
        <ul className = "list-decimal pl-6">
          <li>Darowiznę można przekazać po kliknięciu „Wpłacam” na stronie www.prezentdlababciidziadka.pl w tym celu
            należy postępować zgodnie z instrukcjami zamieszczonymi na stronie operatora płatności PayU.
          </li>
          <li>Przekazywanie darowizn na stronie www.prezentdlababciidziadka.pl odbywa się za pośrednictwem operatora
            płatności – PayU SA z siedzibą w Poznaniu, 60-166 Poznań, przy ul. Grunwaldzkiej 186, krajowa instytucja
            płatnicza, nadzorowana przez Komisję Nadzoru Finansowego, wpisana do Rejestru usług płatniczych pod numerem
            IP1/2012, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy w Poznaniu – Nowe Miasto i
            Wilda w Poznaniu, Wydział VIII Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000274399,
            posiadająca numer identyfikacji podatkowej NIP: 779-23-08-495, REGON 300523444.
          </li>
          <li>Darowiznę można wpłacić przy pomocy przelewu elektronicznego i systemu płatności mobilnych BLIK.</li>
          <li>Wpłaty Darczyńców są chronione przy pomocy bezpiecznego certyfikatu SSL. Dzięki niemu wszystkie przesyłane
            informacje są zaszyfrowane.
          </li>
          <li>Za pośrednictwem strony można przekazywać darowizny jednorazowe.</li>
          <li>Przekazując darowiznę Darczyńca może wybrać jedną z kwot sugerowanych na stronie
            www.prezentdlababciidziadka.pl lub wpisać dowolną, wybraną przez siebie kwotę w pole „Inna kwota”.
          </li>
        </ul>

      </P>
      <SubHeader>III. Zwolnienia podatkowe</SubHeader>
      <P>Zgodnie z przepisami prawa art. 26 ust. 1 pkt 9 ustawy o podatku dochodowym od osób fizycznych istnieje
        możliwość odliczenia od dochodu w roku podatkowym wartość przekazanych darowizn. Darowizny przekazane na
        działania Fundacji można odliczyć od dochodu: osoba fizyczna do kwoty równej 6% dochodu przed opodatkowaniem
        (art. 26 ust. 1 pkt 9 ustawy o podatku od osób fizycznych), a osoba prawna do kwoty równej 10% dochodu przed
        opodatkowaniem (art. 18 ust. 1 pkt. 1 i 7 ustawy o podatku od osób prawnych). Potwierdzenie przelewu należy
        przechowywać 5 lat od dokonania darowizny.
      </P>
      <SubHeader>IV. Reklamacje, zwroty</SubHeader>
      <P>
        <ul className = "list-decimal pl-6">
          <li>W przypadku wystąpienia problemów z przekazaniem darowizny należy je zgłosić Fundacji pod adresem:
            stocznia@stocznia.org.pl
          </li>
          <li>Darczyńca może złożyć reklamację, jeżeli Usługi przewidziane w regulaminach operatora płatności nie
            zostały zrealizowane lub są realizowane niezgodnie z jego postanowieniami. Reklamację można złożyć w formie:
          </li>
          <ul className = "list-decimal pl-6">
            <li>pisemnej na adres PayU S.A., ul. Grunwaldzka 186, 60-166 Poznań</li>
            <li>elektronicznej poprzez formularz https://www.payu.pl/pomoc),</li>
            <li>telefonicznej pod numerem telefonu: + 48 61 628 45 05 (połączenie płatne wg taryfy operatora, z którego
              usług korzysta Darczyńca), od poniedziałku do piątku w godzinach od 8:00 do 20:00.
            </li>
          </ul>
        </ul>
      </P>
      <SubHeader>V. Dane osobowe Darczyńców</SubHeader>
      <P>
        <ul className = "list-decimal pl-6">
          <li>Fundacja Stocznia jest administratorem danych osobowych Darczyńców i zobowiązuje się do ochrony tych
            danych zgodnie z Ogólnym rozporządzeniem o ochronie danych osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE
            L 119 z 04.05.2016) oraz Ustawą o świadczeniu usług drogą elektroniczną (Dz. U. Nr 144, poz. 1204).
          </li>
          <li>Wpłacając darowiznę Darczyńca przyjmuje do wiadomości, że przetwarzanie danych osobowych ma na celu
            obsługę darowizny zgodnie z obowiązującym prawem (art. 6 ust. 1 lit. c RODO). Dane osobowe mogą służyć też w
            celu korespondencji, przesłania podziękowania Darczyńcy za wpłatę, kontaktu z Darczyńcą w przypadku
            problemów z obsługą darowizny lub w celu potwierdzenia wpłynięcia płatności (art. 6 ust. 1 lit. f RODO).
            Dane będą przetwarzane w także w celu wysyłki prezentu - realizacji umowy lub podjęcia działań przed jej
            zawarciem (art. 6 ust. 1 lit. f RODO).
          </li>
          <li>Dane osobowe Darczyńców gromadzone są prze okres 5 lat od czasu ostatniej interakcji (wpłaty).</li>
          <li>Fundacja może gromadzić następujące dane: imię, nazwisko, adres e-mail, adres korespondencyjny, numer
            telefonu i kwotę darowizny – jeśli Darczyńca przekazał te dane.
          </li>
          <li>Dane Darczyńców możemy udostępnić firmom świadczącym usługi na rzecz Fundacji np. druku i korespondencji
            tylko i wyłącznie w celu wysłania prezentu i w oparciu o umowę o powierzeniu danych osobowych i nie mogą
            wykorzystywać ich do innych celów lub przekazywać innym podmiotom.
          </li>
          <li>Darczyńcy mają prawo żądać dostępu do swoich danych, ich poprawiania, usunięcia lub ograniczenia
            przetwarzania. W tym celu należy zgłosić informację na adres: stocznia@stocznia.org.pl lub pocztą tradycyjną
            na adres siedziby Fundacji.
          </li>
          <li>Więcej informacji na temat gromadzenia i przetwarzania danych osobowych przez Fundację znajduje się w
            Polityce Prywatności dostępnej na www.prezentdlababciidziadka.pl.
          </li>
        </ul>

      </P>
    </div>
  );
}

export default RulesDonate;