import React, {useRef} from "react";
import SubmitButton from "../contact_form/SubmitButton";
import {Link} from "react-router-dom";
import { sha256 } from 'js-sha256';
const DotPayForm = ({register, handleSubmit, watch, errors}) => {


  const formDotpay = useRef(null);
  const onSubmit = (data) => {
    formDotpay.current && formDotpay.current.submit();
  };
  const generate_signature = (inputs) => {
   // const sha256 = require('js-sha256');
    const keys = Object.keys(inputs);
    console.log({keys})
    const values = keys.map(key => inputs[key].props);
    const pair = values.map(key => key.name + '=' + encodeURIComponent(key.value).replace(/%20/g, "+"));
    console.log({pair})

    const string = pair.sort().join('&') + '&3419469ce7dc90e5d7b74196612f9b11';
    console.log({string})
    // const hash = require('crypto').createHash('sha256').update(string);
    const hash = sha256(string);
    console.log({hash})
    return hash;
  };

  const watchAllFields = watch();
  // console.log({ watchAllFields });
  // console.log({ errors });

  const inputsHidden = [];

  for (const field in watchAllFields) {
    // console.log(watchAllFields[field]);
    if (field === 'amount') {
      const value = watchAllFields[field];
      inputsHidden.push(
        <input
          type = "hidden"
          key = {inputsHidden.length}
          name = {field}
          value = {watchAllFields[field] ? value*100 : ""}
        />
      );
    } else {
      inputsHidden.push(
        <input
          type = "hidden"
          key = {inputsHidden.length}
          name = {field}
          value = {watchAllFields[field] ? watchAllFields[field] : ""}
        />
      );
    }
  }
  return (
    <>
      <form
        method = "POST"
        action = "https://secure.payu.com/paygw/UTF/NewPayment"
        id = "dot_form"
        ref = {formDotpay}
      >
        {inputsHidden}
        <input type = "hidden" name = "sig"
          // value = {`sender=426938;algorithm=SHA-256;signature=${generate_signature(inputsHidden)}`} />
          value = {generate_signature(inputsHidden)} />

        {/* <input type="hidden" name="id" value="151499" />
        <input type="hidden" name="kwota" value="18" /> 
        <input type="hidden" name="opis" value="List od Mikołaja" /> 
        <input type="hidden" name="imie" value="Michał" />
        <input type="hidden" name="nazwisko" value="Mojek" />
        <input type="hidden" name="email" value="m.mojek@gmail.com" />
        <input type="hidden" name="postcode" value="00-175" />
        <input type="hidden" name="leadId" value="2" />
        <input
          type="hidden"
          name="URL"
          value="https://swiatecznylist.pl/index.php?leadId=2&amp;t=18"
        />
        <input
          type="hidden"
          name="URLC"
          value="https://swiatecznylist.pl/proksyk/form_active/index.php?r=site/payment&amp;leadId=2"
        />
        <input type="hidden" name="type" value="4" />
        <input type="hidden" name="ignore_last_payment_channel" value="1" />
        <input type="hidden" name="api_version" value="dev" /> */}
      </form>
      <div id = "hook_form" className = "mt-12">
        <form onSubmit = {handleSubmit(onSubmit)}>
          <SubmitButton label = "Wpłać" />
        </form>
      </div>
      <div className = "text-center mt-6"><Link to = "/regulamin-darowizny"
        className = "font-normal"
        target = "_blank"> Regulamin darowizny</Link>
      </div>
    </>
  );
};

export default DotPayForm;
