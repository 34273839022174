import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
const ThankYou = () => {
  let { letterId, amount } = useParams();
  return (
    <>
      <div className="center-container narrow">

        <p className="text-xl mb-6">Dziękujemy za udział w akcji "Prezent dla Babci i Dziadka" oraz wpłatę darowizny! <br />
          Otrzymasz od nas wiadomość mailową z potwierdzeniem.</p>
        <p className="text-xl mb-6">

          Dzień Babci i Dziadka już niebawem - wyślij prezent do kolejnej bliskiej Ci osoby starszej!

        </p>
        <p>
          <a href="/">
            <button className="yellow-button">
              WYŚLIJ <strong>PREZENT</strong>
            </button>
          </a>
        </p>

      </div>
    </>
  );
};

export default ThankYou;
