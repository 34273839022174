import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {useSelector} from "react-redux";
import {selectStep} from "../features/letter_order/letterOrderSlice";


const ScrollToTop = () => {
  const step = useSelector(selectStep);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname,step]);

  return null;
};

export default ScrollToTop;
