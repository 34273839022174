import React, {useState} from 'react';

const FaqList = ({index, header, children}) => {
  return (
    <div className = "mb-8">
      <div className = "grid grid-cols-12 gap-0">
        <div className = "text-green-light font-bold text-3xl text-center mb-4">{index}.</div>
        <div className = "col-span-11">
          <div className = " text-green-light font-bold text-3xl">  {header} </div>
          <div className = "text-xl py-4">{children}</div>
        </div>
      </div>

    </div>)
};

const Faq = () => {

  const [currenIndex, setIndex] = useState(0);
  return (
    <>
      <div className = "center-container narrow">
        <h1 className = "text-header green mb-8">FAQ</h1>
        <p className = "text-xl md:text-2xl font-bold mb-8">
          Zebraliśmy odpowiedzi na najczęstsze pytania dotyczące naszej akcji - znajdziesz je tutaj.
          Nie znajdujesz odpowiedzi na swoje pytanie? Napisz do nas: <a className = "text-gray-dark"
          href = "mailto:silowniapamieci@stocznia.org.pl"
          target = "_blank"
          rel = "noreferrer">silowniapamieci@stocznia.org.pl</a>! </p>
      </div>
      <div className = "center-container narrow">
        <FaqList index = {1}
          header = "Do kiedy mogę wysłać upominek „Siłownia Pamięci - ćwiczenia z głową” z życzeniami?">
          Upominki można zamawiać do 16 stycznia 2022 do godziny 24.00, aby mieć pewność, że przesyłka dotrze do
          bliskiej Ci osoby. Upominki będziemy wysyłać za pośrednictwem Poczty Polskiej.
        </FaqList>
        <FaqList index = {2} header = "Kiedy życzenia dotrą do adresatów?">
          Niezwłocznie po prawidłowym wypełnieniu formularza otrzymasz wiadomość na podany adres e-mail z
          potwierdzeniem. Jeśli wszystko przebiegło sprawnie, nasz zespół niezwłocznie przygotuje upominek do wysłania.
          W akcji korzystamy z usług Poczty Polskiej i za jej pośrednictwem będą przesyłane prezenty dla najbliższych.
        </FaqList>
        <FaqList index = {3} header = "Czy dowiem się, że upominek już został wysłany? ">
          Po poprawnym przejściu procesu wyślemy do Ciebie wiadomość mailową z potwierdzeniem. W przypadku niejasności
          możemy też skontaktować się telefonicznie (jeśli podałeś numer telefonu).
        </FaqList>
        <FaqList index = {4} header = "Czy można wysłać przesyłkę za granicę?">
          Nie. Przesyłki wysyłamy tylko do Polski i tylko w języku polskim.
        </FaqList>
        <FaqList index = {5}
          header = "Jak wygląda upominek “Siłownia Pamięci - ćwiczenia z głową” oraz kartka z życzeniami?">
          <p>“Siłownia Pamięci - ćwiczenia z głową” to materiał w okładce i formacie A4, który został przygotowany
            specjalnie z okazji Dnia Babci i Dziadka i zawiera: informacje, dlaczego warto trenować umysł, kilkanaście
            stron zadań stymulujących różne funkcje poznawcze oraz praktyczne ćwiczenia, które można wykonywać każdego
            dnia.
            <br />
            Do upominku możesz dołączyć kartkę z życzeniami - możesz napisać je samodzielnie lub skorzystać z
            proponowanych przez nas treści. Kartka jest w formacie A5, na awersie będzie miała nadrukowaną grafikę (dwa
            dostępne wzory), a na rewersie treść życzeń. Całość zapakowana będzie w kopertę A4 z odświętną grafiką.
            Więcej informacji na stronie: “O naszym prezencie”.
          </p>
        </FaqList>
        <FaqList index = {6} header = "Ile jest różnych wersji upominków?">
          Przygotowaliśmy specjalną wersję „Siłownia Pamięci - ćwiczenia z głową”, do której możesz dodać
          spersonalizowaną kartkę z życzeniami - do wyboru grafiki oraz treści. Życzenia możesz napisać samodzielnie lub
          skorzystać z naszych propozycji, zaadresować je do jednej lub więcej osób, jak również nadawcą może być jedna
          osoba (dorosły lub dziecko), jak i cała rodzina.
        </FaqList>
        <FaqList index = {7} header = "Jak wysłać upominek do więcej niż jednej osoby?">
          Jeśli chcesz obdarować kolejne bliskie Ci osoby, wypełnij formularz ponownie poprzez kliknięcie w button
          WYŚLIJ PREZENT na stronie
          głównej <a href = "https://www.prezentdlababciidziadka.pl">https://www.prezentdlababciidziadka.pl</a>. Nie ma
          żadnych ograniczeń, możesz zamówić dowolną liczbę przesyłek, ale dla każdej konieczne jest osobne wypełnienie
          formularza (m.in. wybranie prezentu, podanie danych adresowych).

        </FaqList>

        <FaqList index = {8} header = "Mam problem z przekazaniem darowizny.">
          Jeśli masz problem z przekazaniem darowizny na naszej stronie, możesz skorzystać z tradycyjnego przelewu
          bankowego na nasz rachunek w Santander Bank Polska SA:
          86 1090 2851 0000 0001 4265 2583, z tytułem przelewu „Darowizna Dzień Babci i Dziadka oraz Twoimi danymi tj.
          imię, nazwisko, adres email”.
          Poinformuj nas o wpłacie drogą mailową lub telefoniczną - silowniapamieci@stocznia.org.pl, 22 378 38 94.

        </FaqList>
        <FaqList index = {9} header = "Ile kosztuje upominek „Siłownia Pamięci - ćwiczenia z głową” wraz z życzeniami?">
          Przygotowanie i wysyłka ćwiczeń „Siłownia Pamięci - ćwiczenia z głową” z życzeniami to koszt około 20 zł.
          Naszym celem
          jest wsparcie osób starszych. Pamiętaj proszę, że każda złotówka zebrana w tej akcji przeznaczona zostanie na
          działanie programu Siłownia Pamięci. Stale pracujemy nad tym, aby troska o zdrowie, w tym funkcje poznawcze w
          wieku senioralnym, stały się w Polsce standardem - aby narzędzia i materiały do treningu umysłu były łatwo
          dostępne i wysokiej jakości. Wybierając wyższą kwotę od sugerowanej pomożesz nam dotrzeć do jeszcze większej
          liczby seniorów. Każda darowizna jest dla nas bardzo ważna i pomocna – pozwala nam skutecznie działać i
          wspierać osoby starsze!
        </FaqList>
        <FaqList index = {10} header = "Skąd macie dane osobowe adresata/nadawcy?">
          Wszystkie dane osobowe przekazywane są nam przez osobę zamawiającą prezent. W naszej akcji oferujemy możliwość
          zamówienia prezentu dla bliskiej osoby wraz z wysłaniem go pod wskazany adres. W tym celu niezbędne są nam
          dane osobowe i korespondencyjne adresata. Dane te są przetwarzane przez Fundację Stocznia w związku z
          realizacją przesyłki i będą usunięte po zakończeniu kampanii.
          Dane osobowe nadawcy - czyli osoby zamawiającej prezent - są nam niezbędne w celu potwierdzenia złożenia
          zamówienia i jego realizacji oraz zarejestrowania wpłaty darowizny. Więcej informacji na temat przetwarzania i
          ochrony danych osobowych znajduje się w Polityce prywatności. W przypadku pytań prosimy o kontakt mailowy
          silowniapamieci@stocznia.org.pl.
        </FaqList>
        <FaqList index = {11}
          header = "Kto jest autorem  materiału “Siłownia Pamięci - ćwiczenia z głową” oraz kartki z życzeniami?">
          “Siłownia Pamięci - ćwiczenia z głową” to materiał opracowany specjalnie z okazji Dnia Babci i Dziadka przez
          zespół Fundacji Stocznia wraz ekspertkami, które posiadają wieloletnie doświadczenie w pracy z osobami
          starszymi w zakresie terapii zajęciowej i aktywizacji.
          Proponowane treści na kartkę z życzeniami przygotowane są przez zespół Fundacji Stocznia, jednak dla osób
          zamawiającym udostępniamy możliwość dowolnej personalizacji życzeń (z ograniczoną liczbą znaków) oraz opcję
          napisania całkowicie własnej treści życzeń (również z ograniczoną liczba znaków).
          Informujemy, że nie ponosimy odpowiedzialności za treść napisanych osobiście życzeń.
        </FaqList>

      </div>
    </>
  );
};

export default Faq;
