import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {selectEventConfig, selectEventStatus,} from "../../event_fetcher/EventFetcherSlice";

import {changeStep, selectCustomChoose, selectLetterContent, selectStep, setupLetter} from "../letterOrderSlice";

import BackgroundSlider from "../../background_slider/BackgroundSlider";
import LetterFormFields from "./LetterFormFields";


import LivePreview from "./LivePreview";
import {CustomChooser} from "./CustomChooser";
import SubmitButton from "../contact_form/SubmitButton";


const LetterGenerator = () => {
  const {letter_types} = useSelector(selectEventConfig);
  const eventStatus = useSelector(selectEventStatus);
  const letterContent = useSelector(selectLetterContent);
  const step = useSelector(selectStep);
  const customChoose = useSelector(selectCustomChoose);
  const [textToLong, setTextToLong] = useState(false)
  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    unregister,
    reset,
    getValues,

    formState: {errors, touchedFields},
  } = useForm({defaultValues: letterContent});

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(setupLetter(data));
  };

  const letterType = watch("letter_type");
  const background = watch("background_id");

  const handleStringLimit = (event) => {
    console.log(event.target.value.length);
    const data = event.target.value;
    if (customChoose === 2) {
      if (data && data.length > 500) {
        setTextToLong(true);
        setTimeout(() => {
          // setValue("custom_full_letter", data.substring(0, 500));
          // setTextToLong(false);
        }, 500);

      } else {
        setTextToLong(false);
      }
    }
  };
  const selectedLetterType = letterType
    ? letter_types.find((element) => element.id === letterType.value)
    : null;
  useEffect(() => {
    reset({...getValues()});
    // this unregister inputs except letter_type
    for (var f in getValues()) {
      if (f !== "letter_type") {
        unregister(f, {keepValue: true});
      }
    }

    // dispatch(setupLetter({ background_id: background }));
  }, [letterType, reset, unregister, getValues, customChoose]);

  if (eventStatus !== "ready") {
    return <div>loading</div>;
  }
  const options = [];

  letter_types.forEach((letter_type) => {
    options.push({value: letter_type.id, label: letter_type.name});
  });

  // console.log({ errors });
  return (
    <>
      <form onSubmit = {handleSubmit(onSubmit)}>
        <div className = "center-container narrow">

          <div className = "container">
            <h2 className = "big mt-0 mb-1 md:mt-10 md:mb-10">Wybierz i spersonalizuj prezent
              <span
                className = "text-base md:text-2xl font-normal block">(na awersie kartki będzie grafika, na rewersie życzenia)</span>
            </h2>
            <p className = "mt-6 mb-6 font-bold text-3xl">Wybierz kartkę</p>
            <BackgroundSlider
              isOnForm = {true}
              register = {register}
              setValue = {setValue}
              background = {background}
              key = "background_slider"
            />
          </div>
        </div>
        {background && (
          <CustomChooser />
        )}
        {customChoose && customChoose === 2 && (
          <div className = "center-container narrow">
            {/*TODO: oprogramuj limit 500 znaków*/}
            <p className = "mb-6 font-bold text-3xl">Wpisz tutaj swoje życzenia
              <span
                className = "text-base md:text-2xl font-normal block">
                Spróbuj zmieścić się w limicie 500 znaków. Ładnie będą wyglądały życzenia/podziękowania składające się z 3 akapitów treści. Wypełnij całość uważnie i sprawdź,
                czy wszystko jest poprawnie.  </span>
            </p>
            <textarea
              className = "w-full py-4 px-3 border-2  border-green-light rounded h-80 bg-green-100 focus-visible:border-green-light"
              onInput = {handleStringLimit}
              {...register("custom_full_letter", {
                maxLength: {value: 500},
                required: true,
              })}
            />
            {textToLong && (
              <p className = "error text-xs italic">
                Twoje życzenia zawierają więcej niż 500 znaków. Zmniejsz ich ilość.

              </p>
            )}
            <div className = "flex mt-8 justify-center gap-6">
              <div>
                <button
                  onClick = {() => dispatch(changeStep(step - 1))}
                  className = "yellow-button"
                >
                  <strong> Wróć</strong>
                </button>
              </div>
              <div>
                <SubmitButton label = "Sprawdź kartkę z życzeniami" />
              </div>
            </div>
          </div>
        )}
        {customChoose && customChoose === 1 && (
          <div className = "center-container narrow">
            <div className = "container">
              <label className = "form-generator-label">Od kogo wysyłasz kartkę? <span className = "font-normal"> (ten wybór ma wpływ na treść życzeń)</span>
              </label>

              <Controller
                name = "letter_type"
                control = {control}
                render = {({field}) => (
                  <Select {...field} options = {options}
                    placeholder = ""
                    className = "react-select-container"
                    classNamePrefix = "react-select"
                    isClearable = {false}
                    isSearchable = {false}
                    theme = {(theme) => ({
                      ...theme,
                      colors: {...theme.colors, primary25: 'neutral50', primary: '#00AD9D'},
                    })} />
                )}
              />

              {selectedLetterType && (
                <>
                  <div className = "text-sm p-4">Uzupełnij poniższą treść życzeń - w tym celu wybierz lub wpisz
                    poszczególne elementy treści (pola do wyboru
                    znajdują się pod ramką z treścią). <br />Wypełnij całość uważnie i sprawdź, czy wszystko jest
                    poprawnie.
                  </div>
                  <div className = "small-preview p-5 border border-gray-400 rounded">

                    <div className = "preview">
                      <LivePreview
                        letter_type = {selectedLetterType}
                        control = {control}
                      />
                    </div>
                  </div>
                  <LetterFormFields
                    letterType = {selectedLetterType}
                    control = {control}
                    register = {register}
                    errors = {errors}
                    setValue = {setValue}
                    touchedFields = {touchedFields}
                    unregister = {unregister}
                  />
                </>
              )}
              <div className = "flex mt-8 justify-center gap-6">
                <div>
                  <button
                    onClick = {() => dispatch(changeStep(step - 1))}
                    className = "yellow-button"
                  >
                    <strong> Wróć</strong>
                  </button>
                </div>
                {selectedLetterType && (

                  <div>
                    <SubmitButton label = "Sprawdź kartkę z życzeniami" />
                  </div>

                )}
              </div>

            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default LetterGenerator;
