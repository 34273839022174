import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import DotPayForm from "./DotPayForm";
import PaymentAmount from "./PaymentAmount";
import {selectEventConfig} from "../../event_fetcher/EventFetcherSlice";


const PaymentChooser = ({sender, letterId, sessionId, timestamp, ipAddress}) => {
  const [selectedAmount, setSelectedAmount] = useState(false);
  const [customAmount, setCustomAmount] = useState("");
  const {event} = useSelector(selectEventConfig);
  if (!sender) {
    sender = {
      first_name: "Test First Name",
      last_name: "Test Last Name",
      email: "m.mojek@gmail.com",
      postal_code: "00-175",
    };
  }

  // : {
  //   payment_account,
  //   payament_amount_one,
  //   payament_amount_two,
  //   minimum_amount,
  // },
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {
      // id: event && event.payment_account,
      order_id: letterId,
      client_ip: ipAddress,
      session_id: sessionId,
      ts: timestamp,
      js: 1,
      pos_id: "4155470",
      pos_auth_key: 'Fmx4HWV',
      first_name: sender.first_name,
      last_name: sender.last_name,
      email: sender.email,
      desc: 'prezentdlababciidziadka.pl',

      // currencyCode: "PLN",
      // continueUrl: `${process.env.REACT_APP_DOMAIN}/dziekujemy/l/${letterId}/amt/0`,
      // notifyUrl: `${process.env.REACT_APP_API_DOMAIN}/urlc`,
      // type: 4,
      // control: letterId,
    },
  });
  useEffect(() => {
    if (event) {
      register("amount", {required: true, min: event.minimum_amount});
    }
  }, [event]);

  const updateUrlAfterAmountSet = (amount) => {
    // const amountRegex = /amt\/[0-9.]*/gm;
    // const url = getValues("continueUrl");
    // const urlUpdated = url.replace(amountRegex, "amt/" + amount);
    // setValue("continueUrl", urlUpdated);
  };
  const handleSelectPayment = (amountVal) => {
    setSelectedAmount(amountVal);
    setValue("amount", "");
    if (amountVal !== "other") {
      setValue("amount", amountVal , {shouldValidate: true});
    } else {
      if (customAmount) {
        setValue("amount", customAmount, {shouldValidate: true});
      }
    }
    updateUrlAfterAmountSet(getValues("amount"));
  };
  const handleOther = (e) => {
    // const amountPreparation = e.target.value.replace(",", ".");
    const amountPreparation = e.target.value;
    setValue("amount", e.target.value, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setCustomAmount(e.target.value);
    updateUrlAfterAmountSet(getValues("amount"));
  };
  if (!event) {
    return "czekaj...";
  }
  return (
    <div className = "center-container narrow-2">
      <div className = "container text-center">
        <div className = "tall ng-scope">
          <div className = "text-center">

            {errors && errors.amount && (
              <div className = "mb-4">
                Wpisana kwota jest nieprawidłowa. Minimalna kwota to 25 zł.
              </div>
            )}
          </div>
          <div className = "md:grid md:grid-cols-3 md:gap-6">
            <div className = "mb-4">
              <PaymentAmount
                amountVal = {event.payament_amount_one}
                selectedAmount = {selectedAmount}
                handleClick = {() =>
                  handleSelectPayment(event.payament_amount_one.toString())
                }
              />
            </div>
            <div className = "mb-4">
              <PaymentAmount
                amountVal = {event.payament_amount_two}
                selectedAmount = {selectedAmount}
                handleClick = {() =>
                  handleSelectPayment(event.payament_amount_two.toString())
                }
              />
            </div>
            <div className = "mb-4">
              <PaymentAmount
                selectedAmount = {selectedAmount}
                amountVal = {false}
                handleClick = {() => handleSelectPayment("other")}
                handleOther = {handleOther}
                minimum_amount = {event.minimum_amount*100}
              />
            </div>
          </div>

          <div className = "row">
            <div className = "col-md-12">
              <DotPayForm
                register = {register}
                handleSubmit = {handleSubmit}
                watch = {watch}
                errors = {errors}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentChooser;
