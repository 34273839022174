import React from "react";

export const KnowMindGym = () =>
<div className="bg-gray-100 -mb-12 md:-mb-16">
  <div className = "center-container md:grid md:grid-cols-5 md:gap-4">
    <div className = "md:col-span-3 know-text px-4 md:px-0">
      <h2 className = "green text-header mb-4 leading-6">
        Poznaj Siłownię Pamięci</h2>

      <p>Prezent dla Babci i Dziadka to element programu Siłownia Pamięci realizowanego przez Fundację Stocznia. Od lat
         opracowujemy materiały i narzędzia do treningu umysłu (np. pamięci, koncentracji, logicznego myślenia i
         kreatywności) dla osób starszych i wszystkie udostępniamy <strong>bezpłatnie na stronie </strong>
        <a href = "https://silowniapamieci.pl" target = "_blank" rel = "noreferrer">www.silowniapamieci.pl</a>.
      </p>
      <p>Z Siłowni Pamięci korzystają seniorzy, którzy profilaktycznie dbają o swoją pamięć, rodziny opiekujące się
         osobami starszymi (w tym cierpiącymi na choroby otępienne), pracownicy różnego rodzaju placówek (np. domy
         dziennej i stałej opieki, kluby seniora, centra aktywności) oraz organizacje społeczne wspierające
         seniorów.
      </p>
      <p> Więcej o naszych działaniach przeczytasz na stronie
         <a href = "https://silowniapamieci.pl" target = "_blank" rel = "noreferrer"> www.silowniapamieci.pl</a>.</p>
    </div>
    <div className="md:col-span-2">
      <div className = "bg-green-100 py-4 px-4 md:py-12 md:px-8 text-xl">
        <h2 className = "green text-header mb-4 leading-6">
          Czy wiesz, że:
        </h2>
        <p className = "px-3 py-4 border-b-2 border-orange-light ">Co czwarty Polak jest w wieku 60 lat i więcej, a za około
                                                              30 lat już ponad 40% Polaków będzie po 60. roku
                                                              życia.</p>
        <p className = "px-3 py-4 border-b-2 border-orange-light">Dla 90% z nas największą wartością jest zdrowie, a dla 70%
                                                             samodzielność życiowa.</p>
        <p className = "px-3 py-4 border-b-2 border-orange-light">W Polsce ponad 500 tys. osób cierpi na choroby otępienne, a
                                                            u blisko 1,6 mln osób występują łagodne
                                                            zaburzenia
                                                            poznawcze.</p>
      </div>
      <small className="text-gray-400 block leading-0 p-2"> Dane w ramce na podstawie - GUS. Ogólnopolskie badanie Polsenior 2, Attitudes Towards Ageing/Bupa Grupa
              Luxmed </small>
    </div>
  </div>
</div>;