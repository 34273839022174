import React from "react";
import {useSelector} from "react-redux";
import {Counter} from "./features/counter/Counter";

import {EventFetcher} from "./features/event_fetcher/EventFetcher";
import {selectEventConfig} from "./features/event_fetcher/EventFetcherSlice";

import Layout from "./layout/Layout";
import MainPage from "./pages/MainPage/MainPage";
import ThankYou from "./pages/ThankYou/ThankYou";
import LetterOrder from "./pages/LetterOrder/LetterOrder";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "./assets/css/bootstrap-theme.min.css";
// import "./assets/css/list_boot.css";
// import "./assets/css/list_media.css";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Loading from "react-fullscreen-loading";
import AboutPage from "./pages/AboutPage/AboutPage";
import HowHelp from "./pages/HowHelp/HowHelp";
import HowToWrite from "./pages/HowToWrite/HowToWrite";
import Faq from "./pages/Faq/Faq";
import {Policy} from "./pages/Rules/Policy";
import {Rules} from "./pages/Rules/Rules";
import RulesDonate from "./pages/Rules/RulesDonate";

// const $ = require("jquery");
// window.$ = $;
// window.jQuery = $;
// require("bootstrap/dist/js/bootstrap.min.js");


function App() {
  const { event } = useSelector(selectEventConfig);
  return (
      <Router>
        <EventFetcher/>
        {event ? (
            <>
              <Layout>
                <Switch>
                  <Route exact path = "/">
                    {event.active ? (<MainPage />) : (<div />)}
                  </Route>
                  <Route exact path = "/kupon/:coupon">
                    <MainPage />
                  </Route>
                  <Route exact path = "/partner/:partner">
                    <MainPage />
                  </Route>
                  <Route exact path = "/partner/:partner/r">
                    <LetterOrder />
                  </Route>
                  <Route exact path = "/backdoor">
                    <MainPage />
                  </Route>
                  <Route path = "/wyslij-prezent/platnosc/:letterId/:email">
                    <LetterOrder />
                  </Route>
                  <Route path = "/wyslij-prezent">
                    <LetterOrder />
                  </Route>
                  <Route path = "/o-fundacji">
                    <AboutPage />
                  </Route>
                  <Route path = "/jak-wyslac-prezent">
                    <HowToWrite />
                  </Route>
                  <Route path = "/jak-pomaga-twoja-darowizna">
                    <HowHelp />
                  </Route>
                  <Route path = "/faq">
                    <Faq />
                  </Route>
                  <Route path = "/polityka-prywatnosci">
                    <Policy />
                  </Route>
                  <Route path = "/regulamin">
                    <Rules />
                  </Route>
                  <Route path = "/regulamin-darowizny">
                    <RulesDonate />
                  </Route>
                  <Route path = "/dziekujemy">
                    <ThankYou />
                  </Route>
                  <Route path = "/blad">
                    <div className="center-container narrow">
                    <h1 className="text-xl">Przykro nam, ale wystąpił błąd w procesie zamawiania prezentu!<br />
                      Otrzymasz od nas wiadomość mailową z informacją i linkiem, pod którym będzie możliwe dokończenie procesu.</h1>
                    </div>
                  </Route>
                  <Route path = "/counter">
                    <Counter />
                  </Route>
                </Switch>
              </Layout>

            </>
        ) : (
            <Loading loading background="#ffffff" loaderColor="#102941"/>
        )}
      </Router>
  );
}

export default App;
