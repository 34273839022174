import React from 'react';


const HelpList = ({amount, children}) => (
  <div className = "pb-6 mb-6 border-b-2 border-orange-light md:grid md:grid-cols-7 md:gap-6 items-center">
    <div className = "col-span-3 md:col-span-1 text-3xl md:text-4xl font-bold text-green-light text-center">{amount}</div>
    <div className = "col-span-4  md:col-span-6 text-center md:text-left">{children}</div>
  </div>
);

const PointList = ({bold, children}) => <li className = "mb-4 pt-1 md:pr-16">
  <span className = "font-bold text-green-light">{bold}</span> {children}</li>;

const HowHelp = () => {

  return (
    <>
      <div className = "center-container narrow">
        <h1 className = "text-header green mb-8">Jak pomaga Twoja darowizna?</h1>
        <p className = "text-xl md:text-2xl font-bold mb-8">
          Pomaga podwójnie! Dając prezent swoim bliskim, wspierasz ich i pomagasz innym osobom starszym w całej
          Polsce. </p>

        <h2 className = "text-xl md:text-3xl font-bold text-green-light">
          Wartościowy prezent dla bliskich
        </h2>
        <div className = "text-base md:text-xl mb-8">
          Upominek i serdeczne życzenia z okazji Dnia Babci i Dziadka z pewnością sprawią przyjemność i miłą
          niespodziankę Twoim bliskim. Materiał “Siłownia Pamięci - ćwiczenia z głową” to nie tylko ciekawa rozrywka,
          ale przede wszystkim praktyczny sposób na zapoznanie się z tematyką treningów pamięci i ich wykonywanie.
          Systematyczne ćwiczenia umysłu są doskonałą profilaktyką zdrowia – stymulują mózg do wysiłku, przez co
          wytwarzają się nowe połączenia neuronalne i dłużej zachowuje on sprawność i gotowość do podejmowania nowych
          wyzwań.
        </div>
        <h2 className = "text-xl md:text-3xl font-bold text-green-light">
          Wsparcie dla innych osób starszych
        </h2>
        <div className = "text-base md:text-xl  mb-8">
          Każda złotówka zebrana w tej akcji przeznaczona zostanie na działanie programu Siłownia Pamięci. Stale
          pracujemy nad tym, aby troska o zdrowie, w tym funkcje poznawcze w wieku senioralnym, stały się w Polsce
          standardem - aby narzędzia i materiały do treningu umysłu były łatwo dostępne i wysokiej jakości.
        </div>
      </div>

      <div className = "center-container narrow">
        <div className = "bg-green-10 bg-opacity-30 p-4 md:p-8 mb-8">
          <h2 className = "text-green-light text-2xl md:text-3xl font-bold mb-6 px-4 pb-6 border-b-2 border-orange-light">Jak
            pomaga Twoja
            darowizna?</h2>
          <HelpList amount = "20 zł">
            pokryje miesięczny koszt utrzymania strony  <a href = "https://silowniapamieci.pl"
            target = "_blank">www.silowniapamieci.pl</a>
          </HelpList>
          <HelpList amount = "30 zł">
            umożliwi wydruk jednej książki do treningów w parze
          </HelpList>
          <HelpList amount = "50 zł">
            pozwoli nam zaprojektować graficznie kartę pracy do zajęć grupowych
          </HelpList>
          <HelpList amount = "70 zł">
            pokryje koszt wysyłki materiałów do 7 osób, które nie mają dostępu do internetu i drukarki
          </HelpList>
          <HelpList amount = "100 zł">
            wesprze opracowanie nowej serii materiałów do profilaktyki pamięci
          </HelpList>
        </div>

      </div>
      <div className = "center-container narrow text-xl">
        <div className = "md:px-12">

          <p className = "font-bold mb-4 mb:mb-8">
            Każda darowizna jest dla nas bardzo ważna i pomocna <br className = "hidden md:block" />– pozwala nam
            skutecznie
            działać i wspierać osoby starsze! </p>

          <p className = "font-bold">
            Wraz z ekspertami w dziedzinie psychologii, terapii zajęciowej i gerontologii opracowujemy materiały i
            narzędzia do treningu umysłu. Wszystkie udostępniamy bezpłatnie na
            stronie <a href = "https://www.silowniapamieci.pl" target = "_blank"> www.silowniapamieci.pl</a>.
            <br /> <span className = "font-normal">A są
            to             między innymi: </span></p>
          <ul className = "square-list mt-2 mb:mt-6 mb-16">
            <PointList bold = "materiały do pracy indywidualnej">
              dla osób starszych, które profilaktycznie dbają o pamięć, </PointList>
            <PointList bold = "materiały do treningów w parze">
              dla rodzin, które wspierają seniorów i chcą z nimi ćwiczyć w domu (również
              z osobami cierpiącymi na choroby otępienne),</PointList>
            <PointList bold = "materiały do treningów grupowych"> dla pracowników
              prowadzących zajęcia z seniorami, np. w domach opieki,
              klubach seniora, uniwersytetach trzeciego wieku,</PointList>
            <PointList bold = "aplikacje MEMO na tablet"> skierowane do osób starszych i
              ich rodzin, które mają dostęp do tabletów i chcą
              trenować pamięć,</PointList>
            <PointList bold = "nagrania wideo,"> czyli filmy szkoleniowe ułatwiające
              prowadzenie treningów umysłu oraz z ćwiczeniami dla
              seniorów.</PointList>
          </ul>
        </div>
      </div>

    </>
  );
};

export default HowHelp;
