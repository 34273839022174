import React from "react";
import {RulesHeader} from "./RulesHeader";
import {SubHeader} from "./SubHeader";
import {P} from "./P";

export const Policy = () => {
  return (

    <div className = "center-container narrow">
      <RulesHeader header = "Polityka prywatności i plików cookies" update = " 20 grudnia 2021 r." />

      <p>
        Jeżeli tutaj trafiłeś, to niezawodny znak, że cenisz swoją prywatność. Doskonale to rozumiemy,
        dlatego oddajemy w Twoje ręce dokument, w którym w jednym miejscu znajdziesz zasady przetwarzania danych
        osobowych oraz wykorzystywania plików cookies i innych technologii śledzących w związku z funkcjonowaniem
        strony
        internetowej www.prezentdlababciidziadka.pl.
      </p>
      <SubHeader>Administrator i sposób kontaktu z nim </SubHeader>
      <P>
        Podstawowe informacje Administratorem danych osobowych, których zasady przetwarzania opisujemy w tym dokumencie,
        jest Fundacja Stocznia (dalej: Stocznia, my) z siedzibą w Warszawie, 00-277, przy Plac Zamkowy 10. W sprawach
        związanych z ochroną danych osobowych oraz szeroko pojętą prywatnością możesz kontaktować się pod adresem
        e-mail: stocznia@stocznia.org.pl lub pocztą tradycyjną na adres siedziby.
      </P>
      <SubHeader>Bezpieczeństwo danych </SubHeader>
      <P>
        Dbamy o bezpieczeństwo Twoich danych osobowych. Przeanalizowaliśmy ryzyka, jakie wiążą się z poszczególnymi
        procesami przetwarzania Twoich danych, a następnie wdrożyliśmy odpowiednie środki bezpieczeństwa i ochrony
        danych osobowych. Na bieżąco monitorujemy stan naszej infrastruktury technicznej, szkolimy nasz personel,
        przyglądamy się stosowanym procedurom, wprowadzamy konieczne usprawnienia.
      </P>
      <SubHeader>Rodzaj danych osobowych, jakie przetwarzamy i ich źródło</SubHeader>
      <P>
        W zależności od celu, możemy przetwarzać następujące informacje na Twój temat:
        <ul className = "square-list">
          <li>imię i nazwisko,</li>
          <li>adres e-mail,</li>
          <li>numer telefonu,</li>
          <li>adres zamieszkania,</li>
          <li>dane zawarte w korespondencji do nas kierowanej.</li>
        </ul>
      </P>
      <P>
        W ograniczony sposób przetwarzamy również dane osoby, dla której zamawiasz prezent:
        <ul className = "square-list">
          <li>imię i nazwisko,</li>
          <li>adres zamieszkania.</li>
        </ul>
      </P>
      <P>
        W większości przypadków sam nam je przekazujesz. Dzieje się tak, gdy:
        <ul className = "square-list">
          <li>wypełniasz formularz adresowy,</li>
          <li>kontaktujesz się z nami.</li>
        </ul>
      </P>
      <SubHeader>Cele i podstawy przetwarzania danych</SubHeader>
      <P>
        Przetwarzamy dane osobowe w kilku celach, którym przyporządkowaliśmy odpowiednie podstawy prawne przetwarzania.
        Okresy przechowywania danych zostały wskazane odrębnie w stosunku do każdego celu przetwarzania. Odnajdziesz te
        informacje w ramach szczegółów poświęconych każdemu odrębnemu celowi przetwarzania.
        Przetwarzamy dane w następujących celach:
        <ul className = "square-list">
          <li>wysłania prezentu – art. 6 ust. 1 lit. b RODO,</li>
          <li>utrzymywanie kontaktu i obsługa korespondencji – art. 6 ust. 1 lit. f RODO,</li>
          <li>obsługa wpłat darowizn – art. 6. Ust. 1. lit c RODO,</li>
          <li>tworzenie archiwum na potrzeby ewentualnej konieczności wykazania pewnych faktów oraz obrony, ustalenia
            lub dochodzenia roszczeń – art. 6 ust. 1 lit. f RODO,
          </li>
          <li> analiza, statystyka i optymalizacja – art. 6 ust. 1 lit. f RODO,</li>
          <li> pliki cookies - art. 6 ust. 1 lit. a RODO.</li>
        </ul>
      </P>
      <SubHeader>Formularz adresowy „Wyślij prezent” - szczegóły </SubHeader>
      <P>Wypełniając formularz adresowy, przekazujesz nam swoje dane oraz dane osoby, dla której zamawiasz prezent.
        Podanie tych danych jest dobrowolne, ale konieczne do otrzymania prezentu. Dane osobowe nadawcy – czyli osoby
        zamawiającej prezent – są nam niezbędne by spersonalizować i wysłać prezent oraz zarejestrować właściwie wpłatę
        darowizny. Dane osobowe adresata – czyli osoby otrzymującej prezent – są nam niezbędne by spersonalizować i
        wysłać prezent (art. 6 ust. 1 lit. b RODO).</P>

      <P>Jeśli dodatkowo wskażesz swój numer telefonu, również będziemy go przetwarzać w celu wyjaśniania ewentualnych
        niejasności związanych z wysłaniem prezentu. Podanie tych danych jest dobrowolne. Podstawą prawną ich
        przetwarzania jest Twoja zgoda (art. 6 ust. 1 lit. a RODO) wyrażona podczas wypełnienia formularza
        adresowego.</P>

      <P>Jeżeli chodzi o przetwarzanie informacji, które nie pochodzą od Ciebie, a zostały zebrane automatycznie przez
        naszą stronę, opieramy się w tym zakresie na naszym prawnie uzasadnionym interesie (art. 6 ust. 1 lit. f RODO)
        polegającym na analizie zachowań osób korzystających z naszej strony w celu optymalizacji działań.</P>

      <P> Dane nadawcy będą przechowywane w naszej bazie w celu ewentualnej obrony roszczeń związanych z wysyłką
        prezentu, co stanowi nasz prawnie uzasadniony interes, o którym mowa w art. 6 ust. 1 lit. f RODO. Dane adresata
        zostaną usunięte z bazy 30 dni po wysłaniu prezentu.</P>

      <P>Masz prawo do dostępu do swoich danych osobowych oraz danych osobowych adresata prezentu, ich sprostowania.
        Masz również prawo usunięcia i ograniczenia przetwarzania danych, wniesienia sprzeciwu wobec ich przetwarzania,
        a także wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych. Skorzystanie z tych praw może się wiązać z
        tym, że nie będziemy mogli wysłać prezentu.</P>
      <SubHeader>Obsługa korespondencji – szczegóły </SubHeader>
      <P>Kontaktując się z nami, w sposób naturalny przekazujesz nam swoje dane osobowe zawarte w treści korespondencji,
        w szczególności adres e-mail oraz imię i nazwisko. Podanie danych jest dobrowolne, ale niezbędne, by nawiązać
        kontakt.</P>

      <P>Twoje dane są w tym przypadku przetwarzane w celu kontaktu z Tobą, a podstawą przetwarzania jest art. 6 ust. 1
        lit. f RODO, czyli nasz prawnie uzasadniony interes. Podstawą prawną przetwarzania po zakończeniu kontaktu jest
        również nasz usprawiedliwiony cel w postaci archiwizacji korespondencji na potrzeby zapewnienia sobie możliwości
        wykazania określonych faktów w przyszłości (art. 6 ust. 1 lit. f RODO).</P>

      <P>Treść korespondencji może podlegać archiwizacji i nie jesteśmy w stanie jednoznacznie określić, kiedy zostanie
        usunięta. Masz prawo do domagania się przedstawienia historii korespondencji, jaką z nami prowadziłeś (jeżeli
        podlegała archiwizacji), jak również domagać się jej usunięcia, chyba że jej archiwizacja jest uzasadniona z
        uwagi na nasze nadrzędne interesy, np. obrona przed potencjalnymi roszczeniami z Twojej strony.</P>
      <SubHeader>Wpłata darowizny – szczegóły</SubHeader>
      <P>Fundacja przyjmuje darowizny przekazywane przelewem na konto bankowe lub on-line za pośrednictwem systemu PayU.
        W przypadku darowizny przekazanej za pośrednictwem systemu PayU prosimy o podanie imienia i nazwiska, abyśmy
        wiedzieli, kto jest stroną transakcji (np. by móc wystawić zaświadczenie do celów podatkowych), kwoty darowizny
        oraz adresu e-mail, który jest niezbędny do technicznej obsługi płatności. Podanie tych danych jest dobrowolne,
        ale niezbędne do przekazania darowizny za pośrednictwem systemu wpłat online.</P>

      <P>W przypadku darowizny przekazanej nam przelewem na rachunek bankowy przetwarzamy dane osobowe przekazane nam
        przez bank, czyli: imię i nazwisko, adres, numer rachunku, informację o wysokości darowizny (informacje te
        widoczne są w opisie przelewu), a także adres e-mail, jeśli zostanie on podany w tytule przelewu. Jest to
        niezbędne do wykonania umowy oraz wypełnienia ciążących na Fundacji obowiązków prawnych (art. 6 ust. 1 lit. b i
        c RODO).</P>

      <P>Na adresy pocztowe oraz adresy e-mail podane w przelewach bankowych oraz udostępnione w serwisie PayU wysyłamy
        podziękowania za wsparcie oraz informacje o naszej działalności i o możliwościach dalszego wsparcia
        organizacji.</P>

      <P>Jako Darczyńca masz prawo żądać dostępu do swoich danych, ich poprawiania, usunięcia lub ograniczenia
        przetwarzania. W każdej chwili możesz też wycofać swoją zgodę na ich dalsze przetwarzanie.</P>
      <SubHeader>Odbiorcy danych osobowych </SubHeader>
      <P>Współpracujemy i korzystamy z usług świadczonych przez podmioty trzecie. Część z tych usług wiąże się z
        przetwarzaniem Twoich danych osobowych. </P>
      <P>
        Zewnętrzni usługodawcy, którzy biorą udział w przetwarzaniu Twoich danych osobowych, to:
        <ul className = "square-list">
          <li>podmioty świadczące usługi druku i wysyłki,</li>
          <li>hostingodawca, który przechowuje dane na serwerze,</li>
          <li>dostawca usługi chmury obliczeniowej, w której przechowywane są kopie zapasowe mogące zawierać Twoje dane
            osobowe,
          </li>
          <li>dostawca systemu mailingowego, w którym przechowywane są Twoje dane,</li>
          <li>dostawca systemu CRM, w którym przechowujemy Twoje dane w celu usprawnienia procesu obsługi klienta oraz w
            celach archiwalnych,
          </li>
          <li>agencja marketingowa, która wspiera nas w promowaniu Siłowni Pamięci,</li>
          <li>kancelaria prawna, która uzyskuje dostęp do danych, jeżeli jest to konieczne do świadczenia pomocy prawnej
            na naszą rzecz,
          </li>
          <li>podmiot świadczący usługi w zakresie obsługi technicznej, który uzyskuje dostęp do danych, jeżeli
            prowadzone prace techniczne dotyczą obszarów, w których znajdują się dane osobowe,
          </li>
          <li>pozostali podwykonawcy, którzy uzyskują dostęp do danych, jeżeli zakres ich działań wymaga takowego
            dostępu.
          </li>
        </ul>
      </P>
      <P>Ponadto, w razie zaistnienia takiej konieczności, Twoje dane osobowe mogą być udostępniane podmiotom, organom
        lub instytucjom uprawnionym do uzyskania dostępu do danych na podstawie przepisów prawa, takim jak służby
        policyjne, bezpieczeństwa, sądy, prokuratury.</P>
      <SubHeader>Analiza, statystyka, optymalizacja – szczegóły</SubHeader>
      <P>
        Korzystamy z narzędzi, które gromadzą na Twój temat szereg informacji związanych z korzystaniem z naszej
        strony. Chodzi, w szczególności, o następujące informacje:
        <ul className = "square-list">
          <li>informacje o systemie operacyjnym i przeglądarce internetowej, z której korzystasz,</li>
          <li>przeglądane postronny,</li>
          <li>czas spędzony na stronie,</li>
          <li>przejścia pomiędzy poszczególnymi podstronami,</li>
          <li>kliknięcia w poszczególne linki,</li>
          <li>źródło, z którego przechodzisz do naszej strony,</li>
          <li>przedział wieku, w którym się znajdujesz,</li>
          <li>Twoja płeć,</li>
          <li>Twoja przybliżona lokalizacja ograniczona do miejscowości.</li>
          <li>Twoje zainteresowania określone na podstawie aktywności w sieci.</li>
        </ul>
      </P>

      <P>Informacje te same w sobie nie mają, w naszej ocenie, charakteru danych osobowych. Informacje te poddajemy
        analizie w celu zarządzania stroną, zapewnienia sprawnego działania oferowanych w jej ramach funkcjonalności,
        analizowania skuteczności komunikacji, optymalizacji naszych stron pod kątem doświadczenia użytkownika,
        skuteczności i konwersji.
        Robimy to, opierając się o nasz prawnie uzasadniony interes, o którym mowa w art. 6 ust. 1 lit. f RODO
        polegający na optymalizacji naszych stron internetowych.
      </P>

      <P>Ponieważ informacje te gromadzone są przez zewnętrzne narzędzia, z których korzystamy, informacje te
        przetwarzane są również przez dostawców narzędzi na zasadach wynikających z ich regulaminów oraz polityk
        prywatności. Zasadniczo, informacje te wykorzystywane są do świadczenia i ulepszania usług, zarządzania nimi,
        opracowywania nowych usług, mierzenia skuteczności reklam, ochrony przez oszustwami i nadużyciami, a także
        personalizowania treści i reklam wyświetlanych w poszczególnych serwisach, witrynach i aplikacjach.
      </P>

      <SubHeader>Przekazywanie danych do państw trzecich lub organizacji międzynarodowych</SubHeader>

      <P>Część operacji przetwarzania Twoich danych osobowych może wiązać się z ich przekazywaniem do państw trzecich.
        Przekazujemy Twoje dane osobowe do państw trzecich w związku z korzystaniem z narzędzi, które przechowują dane
        osobowe na serwerach zlokalizowanych w państwach trzecich, w tym w USA. Dostawcy tych narzędzi gwarantują
        odpowiedni poziom ochrony danych osobowych poprzez stosowne mechanizmy zgodności przewidziane przez RODO, w
        szczególności poprzez korzystanie ze standardowych klauzul umownych.</P>

      <P>Do przechowywania danych osobowych na serwerach zlokalizowanych w państwach trzecich dochodzi w ramach
        następujących narzędzi usługi Google w ramach pakietu G-Suite, których dostawcą jest Google Ireland Limited,
        Gordon House, Barrow Street, Dublin 4, Ireland - w zakresie wszystkich danych, jakie przetwarzane są w ramach
        usług Google, w tym również tych danych, które zawarte są w plikach podlegających synchronizacji z Google
        Drive.</P>

      <P>Przypominamy w tym miejscu również, że korzystamy z narzędzi zewnętrznych, takich jak Facebook, które mogą
        gromadzić anonimowe informacje na Twój temat. Dostawcy tych narzędzi często wykorzystują do przechowywania
        gromadzonych informacji serwery zlokalizowane na terenie całego świata, w szczególności w Stanach Zjednoczonych
        Ameryki (USA).</P>

      <SubHeader>Profilowania, zautomatyzowany sposób podejmowania decyzji </SubHeader>

      <P> Nie podejmujemy wobec Ciebie decyzji opierających się wyłącznie na zautomatyzowanym przetwarzaniu, w tym
        profilowaniu, które wywoływałyby wobec Ciebie skutki prawne lub w podobny sposób istotnie na Ciebie
        wpływały. </P>

      <SubHeader> Pliki cookies – czy korzystamy i czym one właściwie są? </SubHeader>

      <P>Nasza strona, podobnie jak niemal wszystkie inne strony internetowe, wykorzystuje pliki cookies. Cookies to
        niewielkie informacje tekstowe, przechowywane na Twoim urządzeniu końcowym (np. komputerze, tablecie,
        smartfonie), które mogą być odczytywane przez nasz system teleinformatyczny (cookies własne) lub systemy
        teleinformatyczne podmiotów trzecich (cookies podmiotów trzecich). W plikach cookies mogą być zapisywane i
        przechowywane informacje, do których następnie systemy teleinformatyczne mogą uzyskiwać dostęp w określonych
        celach.</P>

      <P>Niektóre używane przez nas cookies są usuwane po zakończeniu sesji przeglądarki internetowej, tzn. po jej
        zamknięciu (tzw. cookies sesyjne). Inne cookies są zachowywane na Twoim urządzeniu końcowym i umożliwiają nam
        rozpoznanie Twojej przeglądarki przy kolejnym wejściu na stronę (trwałe cookies).</P>

      <SubHeader>Podstawy do korzystania z plików cookies</SubHeader>
      <P>Z plików cookies korzystamy na podstawie Twojej zgody, za wyjątkiem sytuacji, gdy pliki cookies są niezbędne do
        prawidłowego świadczenia na Twoją rzecz usługi drogą elektroniczną.</P>
      <P>W zakresie Twojej zgody na pliki cookies, przyjmujemy wariant, zgodnie z którym taką zgodę wyrażasz poprzez
        ustawienia swojej przeglądarki internetowej lub dodatkowego oprogramowania wspierającego zarządzanie plikami
        cookies. Przyjmujemy, że godzisz się na wszystkie pliki cookies przez nas stosowane, które nie są blokowane
        przez Twoją przeglądarkę lub dodatkowe oprogramowanie, z którego korzystasz.</P>
      <P>Pamiętaj, że wyłączenie lub ograniczenie obsługi plików cookies może uniemożliwiać używanie części funkcji
        dostępnych na naszej stronie oraz powodować trudności w korzystaniu z naszej strony, jak również z wielu innych
        stron internetowych, które stosują cookies.</P>
      <SubHeader>Wyłączenie plików cookies</SubHeader>
      <P>Możesz zarządzać ustawieniami plików cookies w ramach swojej przeglądarki internetowej poprzez blokowanie
        wszystkich lub wybranych plików cookies. Możesz również blokować pliki cookies konkretnych witryn. W każdej
        chwili możesz również usunąć zapisane wcześniej pliki cookies oraz inne dane witryn i wtyczek.</P>
      <P>Przeglądarki internetowe oferują również możliwość korzystania z trybu incognito. Możesz z niego skorzystać,
        jeśli nie chcesz, by informacje o odwiedzonych stronach i pobranych plikach zostały zapisane w historii
        przeglądania i pobierania. Pliki cookie utworzone w trybie incognito są usuwane w momencie zamknięcia wszystkich
        okien tego trybu.</P>
      <SubHeader>Pliki cookies podmiotów zewnętrznych </SubHeader>
      <P>W ramach naszej strony funkcjonują pliki cookies podmiotów zewnętrznych: Google Analytics, Facebook. Strony
        internetowe podmiotów zewnętrznych stosują własne zasady dotyczące prywatności i korzystają z własnych
        cookies.</P>
      <P>Google Analytics w sposób automatyczny gromadzi informacje o Twoim korzystaniu z naszej strony. Zgromadzone w
        ten sposób informacje są najczęściej przekazywane do serwerów Google, które mogą znajdować się w całym świecie i
        tam przechowywane.</P>
      <P>Podkreślamy, że w ramach Google Analytics gromadzone dane nie są kojarzone z konkretnymi osobami
        przeglądającymi nasza stronę. Każdy może korzystać z niej anonimowo – nie wymagamy rejestracji.</P>
      <P>
        Tego narzędzia używamy do:
        <ul className = "square-list">
          <li>generowania i analizowania statystyk odwiedzin stron, który zbiera przy pomocy skryptu: adres IP (jest on
            anonimizowany, ale dostęp do pełnego adresu IP zapisywanego w logach serwera mają osoby posiadające
            uprawnienia administratora),
          </li>
          <li>typ urządzenia, z którego następuje połączenie,</li>
          <li>rozdzielczość ekranu, na którym strony były wyświetlane,</li>
          <li>typ systemu operacyjnego,</li>
          <li>nazwę domeny, z której użytkownik wchodzi na naszą stronę,</li>
          <li>dane lokalizacyjne (państwo, miasto),</li>
          <li>informacje o tym, jakie treści na naszej stronie odwiedził dany użytkownik.</li>
        </ul>
      </P>
      <P>Działania w tym zakresie realizujemy, opierając się na naszym prawnie uzasadnionym interesie, polegającym na
        tworzeniu statystyk i ich analizie w celu optymalizacji naszych stron internetowych.</P>
      <P>Usługi Google Analytics i Google Analytics 360 uzyskały certyfikat niezależnego standardu bezpieczeństwa ISO
        27001. ISO 27001 jest jednym z najczęściej uznawanych standardów na świecie i poświadcza spełnianie odpowiednich
        wymogów przez systemy obsługujące Google Analytics i Google Analytics 360.</P>
      <P>Jeżeli jesteś zainteresowany szczegółami związanymi z wykorzystywaniem przez Google danych z witryn i
        aplikacji, które korzystają z usług Google, zachęcamy do zapoznania się z tymi informacjami:
        https://policies.google.com/technologies/partner-sites.</P>
      <SubHeader>Facebook Pixel</SubHeader>
      <P>Na naszej stronie korzystamy z narzędzi marketingowych, takich jak Facebook Pixel, który umożliwia nam
        kierowanie do osób, które odwiedzają naszą stronę spersonalizowanych reklam w serwisie Facebook. Wiąże się to z
        wykorzystywaniem cookies firmy Facebook. Więcej informacji o polityce Facebooka znajduje się na stronie firmy
        Facebook. Każdy użytkownik serwisu Facebook może zarządzać osobistymi ustawieniami prywatności na swoim koncie w
        serwisie Facebook.
      </P>
      <SubHeader>Logi serwera </SubHeader>
      <P>Korzystanie ze strony wiąże się z przesyłaniem zapytań do serwera, na którym przechowywana jest strona. Każde
        zapytanie skierowane do serwera zapisywane jest w logach serwera.</P>
      <P>Logi obejmują m.in. Twój adres IP, datę i czas serwera, informacje o przeglądarce internetowej i systemie
        operacyjnym z jakiego korzystasz. Logi zapisywane i przechowywane są na serwerze.</P>
      <P>Dane zapisane w logach serwera nie są kojarzone z konkretnymi osobami korzystającymi ze strony i nie są
        wykorzystywane przez nas w celu Twojej identyfikacji.</P>
      <P>Logi serwera stanowią wyłącznie materiał pomocniczy służący do administrowania stroną, a ich zawartość nie jest
        ujawniana nikomu poza osobami upoważnionymi do administrowania serwerem.</P>
      <SubHeader>Uprawnienia jakie przysługują Ci w związku z przetwarzaniem Twoich danych osobowych </SubHeader>
      <P>
        RODO przyznaje Ci następujące uprawnienia związane z przetwarzaniem Twoich danych osobowych:
        <ul className = "square-list">
          <li>prawo dostępu do swoich danych oraz otrzymania ich kopii,</li>
          <li>prawo do sprostowania (poprawiania) swoich danych,</li>
          <li>prawo do usunięcia danych (jeżeli Twoim zdaniem nie ma podstaw, abyśmy przetwarzali Twoje dane, możesz
            żądać, abyśmy je usunęli),

          </li>

          <li>prawo do ograniczenia przetwarzania danych (możesz żądać, abyśmy ograniczyli przetwarzanie danych
            wyłącznie do ich przechowywania lub wykonywania uzgodnionych z Tobą działań, jeżeli w Twojej opinii mamy
            nie
            prawidłowe dane lub przetwarzamy je bezpodstawnie),
          </li>
          <li>prawo do wniesienia sprzeciwu wobec przetwarzania danych (masz prawo do sprzeciwu wobec przetwarzania
            danych na podstawie prawnie uzasadnionego interesu; powinieneś wskazać szczególną sytuację, która Twoim
            zda
            niem uzasadnia zaprzestanie przez nas przetwarzania objętego sprzeciwem; przestaniemy przetwarzać Twoje
            dane w tych celach, chyba że wykażemy, że podstawy przetwarzania przez nas danych są nadrzędne wobec Twoich
            praw lub też, że Twoje dane są nam niezbędne do ustalenia, dochodzenia lub obrony roszczeń),
          </li>
          <li>prawo do przenoszenia danych (masz prawo otrzymać od nas w ustrukturyzowanym, powszechnie używanym
            formacie nadającym się do odczytu maszynowego dane osobowe, które dostarczyłeś nam na podstawie umowy lub
            Two
            jej zgody; możesz nam zlecić przesłanie tych danych bezpośrednio innemu podmiotowi),
          </li>
          <li>prawo do cofnięcia zgody na przetwarzanie danych osobowych, jeżeli uprzednio taką zgodę wyraziłeś,</li>
          <li>prawo wniesienia skargi do organu nadzorczego (jeżeli stwierdzisz, że przetwarzamy dane niezgodnie z
            pra
            wem, możesz złożyć w tej sprawie skargę do Prezesa Urzędu Ochrony Danych Osobowych lub innego właściwego
            organu nadzorczego).

          </li>
        </ul>

      </P>
      <P>Zasady związane z realizacją wskazanych powyżej uprawnień zostały opisane szczegółowo w art. 16 – 21 RODO.
        Zachęcamy do zapoznania się z tymi przepisami. Ze swojej strony uważamy za potrzebne wyjaśnić Ci, że wskazane
        powyżej uprawnienia nie są bezwzględne i nie będą przysługiwać Ci w stosunku do wszystkich czynności
        przetwarzania Twoich danych osobowych.</P>
      <P>Zawsze możesz również zwrócić się do nas z żądaniem udostępnienia Ci informacji o tym, jakie dane na Twój temat
        posiadamy oraz w jakich celach je przetwarzamy. Wystarczy, że wyślesz wiadomość na adres
        stocznia@stocznia.org.pl. Dołożyliśmy jednak wszelkich starań, by interesujące Cię informacje zostały
        wyczerpująco przedstawione w niniejszej polityce prywatności. Podany powyżej adres e-mail możesz wykorzystać
        również w razie jakichkolwiek pytań związanych z przetwarzaniem Twoich danych osobowych.</P>
      <SubHeader>Zarządzenie prywatnością</SubHeader>
      <P>
        Dla Twojej wygody zebraliśmy jeszcze raz te informacji w jednym miejscu. Poniżej znajdziesz przykłady możliwości
        zarządzania swoją prywatnością:
        <ul className = "square-list">
          <li>ustawienia cookies w ramach przeglądarki internetowej,</li>
          <li>wtyczki do przeglądarek wspierające zarządzanie plikami cookies np. Ghostery,</li>
          <li>dodatkowe oprogramowanie zarządzające plikami cookies,</li>
          <li>tryb incognito w przeglądarce internetowej,</li>
          <li>mechanizm zarządzania plikami cookies z poziomu naszej strony,</li>
          <li>Google Analytics Opt-out: https://tools.google.com/dlpage/gaoptout,</li>
          <li>Google Ads Settings: https://adssettings.google.com/,.</li>
        </ul>
      </P>
      <P>Dołożyliśmy wszelkich starań by niniejszy dokument dostarczył Ci jak najdalej idącej wiedzy w ważnych dla
        Ciebie kwestiach. Jeżeli cokolwiek jest dla Ciebie niejasne, chcesz dowiedzieć się więcej lub po prostu
        porozmawiać o Twojej prywatności, napisz do nas na adres stocznia@stocznia.org.pl.</P>
      <P>W celu uaktualnienia informacji zawartych w niniejszej Polityce prywatności oraz jej zgodności z obowiązującymi
        przepisami prawa, niniejsza Polityka prywatności może ulec zmianie. Wraz ze zmianą treści dokumentu, zmieniona
        zostanie data jego aktualizacji, zamieszczana na wstępie niniejszej Polityki Prywatności. Natomiast o każdej
        istotnej zmianie Użytkownik zostanie powiadomiony poprzez informację umieszczoną na stronie internetowej lub
        bezpośrednio. W celu zasięgnięcia informacji o sposobie ochrony danych osobowych, Administrator rekomenduje
        Użytkownikom regularne zapoznawanie się z niniejszymi zasadami Polityki Prywatności.</P>

    </div>
  )
};