import React, { useEffect } from "react";
import LetterFieldSelect from "./LetterFieldSelect";
import LetterFieldInput from "./LetterFieldInput";

import { useWatch } from "react-hook-form";
import * as PropTypes from "prop-types";
import LetterFieldMultiSelect from "./LetterFieldMultiSelect";




const LetterField = ({
  letterField,
  control,
  register,
  errors,
  setValue,
  touchedFields,
  unregister,
}) => {
  const formWatch = useWatch({
    control,
  });
  const conditional_field = letterField.conditional_letter_field;

  // const conditional_field_change =
  //   conditional_field &&
  //   formWatch &&
  //   formWatch["field_" + conditional_field.id];

  useEffect(() => {
    if (conditional_field) {
      if (!formWatch["field_" + conditional_field.id]) {
        return <></>;
      }
    }
  }, []);

  return (
    <>
      {letterField.field_type !== "vi" && (
        <>
          {letterField.field_type === "in" && (
            <LetterFieldInput
              letterField={letterField}
              errors={errors}
              register={register}
              control={control}
              setValue={setValue}
              touchedFields={touchedFields}
              unregister={unregister}
            />
          )}
          {letterField.field_type === "se" && (
            <LetterFieldSelect
              letterField={letterField}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              touchedFields={touchedFields}
              unregister={unregister}
            />
          )}
          {letterField.field_type === "ms" && (
            <LetterFieldMultiSelect
              letterField={letterField}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              touchedFields={touchedFields}
              unregister={unregister}
            />
          )}
        </>
      )}
    </>
  );
};

export default LetterField;
