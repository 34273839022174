import React, {useEffect, useState} from "react";
import {useWatch} from "react-hook-form";

import LivePreviewContent from "./LivePreviewContent";

const getField = (fields, field) => {

  for (var index in fields) {
    if (field.startsWith('multi_') && fields[index].id === parseInt(field.split("__")[1])) {
      const lfo = fields[index].letter_field_options
      let field_obj = fields[index]
      for (let index_lfo in lfo) {
        if (lfo[index_lfo].is_open) {
          field_obj = {...field_obj, multi: true}
        }
      }
      return field_obj;
    }
    if (fields[index].id === parseInt(field.split("_")[1])) {
      return fields[index];
    }
    if (fields[index].id === parseInt(field.split("__open")[0])) {
      return fields[index];
    }
  }
};
const replaceOpenInMulti = (data, formValue) => {
  let newData = [...data]
  for (let index in newData) {
    if (newData[index].open) {
      let chosenOption = newData[index]
      chosenOption = {...chosenOption, display: formValue}
      newData[index] = chosenOption
    }
  }
  return newData
}
const prepareData = (formWatch, letter_type) => {
  const data = {};
  for (var fw in formWatch) {
    // console.log({fw}) // multi_o__2
    const formValue = formWatch[fw]; // value
    const field = getField(letter_type.letter_fields, fw);
    if (field) {
      if (field.multi) {
        data[field.input_tag_name] = replaceOpenInMulti(data[field.input_tag_name], formValue);
      } else {
        data[field.input_tag_name] = formValue;
      }
    }
  }

  for (let vi_field of letter_type.letter_fields.filter(
    (f) => f.field_type === "vi"
  )) {
    // console.log({ vi_field });
    const vi_options = vi_field.letter_field_options;

    for (let vi_o of vi_options) {
      if (vi_field.conditional_letter_field) {
        let vi_label = "";
        if (vi_o.conditional_letter_field_option) {
          const watchConditional =
            formWatch["field_" + vi_field.conditional_letter_field.id];
          if (
            watchConditional &&
            watchConditional.value === vi_o.conditional_letter_field_option.id
          ) {
            vi_label = vi_o.label;
            data[vi_field.input_tag_name] = vi_label;
            break;
          }
        }
        data[vi_field.input_tag_name] = vi_label;
      }
    }
  }
  return data;
};
const LivePreview = ({letter_type, control}) => {
  const formWatch = useWatch({control});
  const [data, setData] = useState({});
  const [renderCounter, setRenderCounter] = useState(0);

  useEffect(() => {

    setData(prepareData(formWatch, letter_type));
    setRenderCounter(renderCounter + 1);
  }, [formWatch]);

  return (
    <>

      <LivePreviewContent data = {data} template = {letter_type.template} />
    </>
  )
    ;
};

export default LivePreview;
