import React, {useEffect} from "react";
import {selectEventConfig} from "../../features/event_fetcher/EventFetcherSlice";

import {
  checkPartnerCodeAsync,
  getCouponSalesForceIdAsync,
  selectCouponState
} from "../../features/letter_order/letterOrderSlice";

import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CouponError} from "./MainPageStyled";
import ThreeCircles from "./ThreeCircles";
import {MaterialInfo} from "./MaterialInfo";
import silownia from "../../assets/images/stocznia/silownia-logo-bit.png"
import {KnowMindGym} from "./KnowMindGym";

const MainPage = () => {
  let params = useParams();

  const dispatch = useDispatch();
  const {event} = useSelector(selectEventConfig);
  const couponState = useSelector(selectCouponState);
  useEffect(() => {
    if (params.coupon) {
      dispatch(
        getCouponSalesForceIdAsync({coupon: params.coupon, event: event.id})
      );
    }
  }, []);

  useEffect(() => {
    if (params.partner) {
      dispatch(
        checkPartnerCodeAsync({partner: params.partner, event: event.id})
      );
    }
  }, []);

  return (
    <>
      {couponState && couponState === "error" && (
        <CouponError>Niepoprawny kod kuponu</CouponError>
      )} {couponState && couponState === "invalid" && (
      <CouponError>Twój kupon został już wykorzystany</CouponError>
    )}
      <div className = "center-container my-8 narrow mb-6 -mt-8 md:-mt-12">
        <h2 className = "text-header green mb-4 mt-4">
          Świętujemy wspólnie Dzień Babci i Dziadka
        </h2>
        <p className = "md:text-2xl">
          W tym roku nie musisz zastanawiać się nad wyjątkowym prezentem dla babci, dziadka
          lub innej bliskiej Ci osoby starszej. Zrobiliśmy to za Ciebie.
        </p>
        <h2 className = "text-header green mt-6 mb-2">
          Wybierając nasz prezent:
        </h2>
        <ul className = "md:text-2xl square-list">
          <li>sprawisz przyjemność bliskim,</li>
          <li>zadbasz o ich zdrowie,</li>
          <li>pomożesz nam wspierać innych seniorów (dzięki Twojej darowiźnie),</li>
          <li >otrzymasz od nas niespodziankę <sup>*</sup>. <br />
            <div className="text-sm leading-tight mt-4">  <sup>*</sup> Zamów upominek do 16.01, a otrzymasz w lutym od nas
              dodatkowy pakiet ćwiczeń - prześlemy maila z plikiem .pdf do samodzielnego wydruku.

            </div>
            <div className="text-sm leading-tight mt-1 ml-2"> Dla zamówień złożonych po tym terminie nie gwarantujemy, że przesyłka pocztowa dotrze do bliskich do 21 stycznia.</div>
          </li>
        </ul>
      </div>
      <div className = "background-green-noise">
        <div className = "center-container my-8 narrow">
          <h2 className = "text-header white mt-6 mb-6">
            Spraw prezent, który pomaga
          </h2>
          <p className = "md:text-2xl mb-10">
            <strong>Z okazji Dnia Babci i Dziadka wyślij do nich prezent</strong>
            - spersonalizowaną kartkę z życzeniami i piękną grafiką
            oraz nasz najnowszy, niedostępny nigdzie indziej, materiał “Siłownia Pamięci - ćwiczenia z głową”. To
            idealny upominek dla wszystkich, którzy dbają o zdrowie, chcą dowiedzieć się więcej na temat treningów
            pamięci i - mamy nadzieję - podejmą się tej aktywności na stałe.
          </p>
        </div>
        <div className = "center-container mt-20 lg:mt-0 lg:pt-0">
          <ThreeCircles />
        </div>
        <div className = "center-container narrow">
          <p className = "md:text-3xl mb-10 text-center" >
            Wybierz kartkę i treść życzeń, a my zapakujemy prezent w kopertę z odświętną grafiką i wyślemy na wskazany
            przez Ciebie adres.
            <br /><strong>To będzie prawdziwa niespodzianka dla Twoich bliskich! </strong>
          </p>
          <p className = "text-center mb-8">
            <Link to = "/wyslij-prezent" className = "yellow-button yellow-bigger">
              Wyślij <strong>prezent</strong>
            </Link>
          </p>

        </div>
      </div>

      <MaterialInfo />
      <div className = "bg-gray-100  py-10">
        <div className = "flex center-container justify-center px-4 md:px-0">
          <img src = {silownia} alt = "silownia" />
        </div>
      </div>
      <KnowMindGym />

      {/*<WritingProcess/> <LettersContainer/> <LetterCounter/> <ShortInfo/>*/}
    </>
  );
};

export default MainPage;
