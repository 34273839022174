import React, {useState} from "react";
import {useSelector} from "react-redux";
import Slide from "./Slide";
import {selectEventConfig} from "../event_fetcher/EventFetcherSlice";

const BackgroundSlider = ({isOnForm, register, setValue, background}) => {
  const {backgrounds} = useSelector(selectEventConfig);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedBackground, setSelectedBackground] = useState(background);

  const handleChangeCurentSlide = (arrow) => {
    if (arrow === "right") {
      if (currentSlide < backgrounds.length - 1) {
        setCurrentSlide(currentSlide + 1);
      }
    }
    if (arrow === "left") {
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
      }
    }
  };
  const handleChangeSelectedBackground = (background) => {
    if (isOnForm) {
      setSelectedBackground(background);
      setValue("background_id", background, {shouldValidate: true});
    }
  };
  if (!backgrounds) {
    return "";
  }

  return (
    <>
      {isOnForm && (
        <input
          type = "hidden"
          {...register("background_id", {required: true})}
        />
      )}

      <div className = "flex gap-1 md:gap-8">
        {backgrounds.map((background, index) => (
          <div className = "w-1/2 text-center" key = {background.id}>
            <Slide
              isOnForm = {isOnForm}
              background = {background}
              index = {index}
              current = {index === currentSlide}
              isSelected = {background.id === selectedBackground}
              handleChangeSelectedBackground = {handleChangeSelectedBackground}

            />
          </div>
        ))}
      </div>
    </>
  );
};

export default BackgroundSlider;
