import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { changeStep, selectStep, getLetterInfoAsync } from "./letterOrderSlice";
import LetterGenerator from "./letter_generator/LetterGenerator";
import ContactForm from "./contact_form/ContactForm";
import Payment from "./payment/Payment";
import Preview from "./preview/Preview";
import HasPostcard from "./letter_generator/HasPostcard";

export function LetterOrderFeature() {
  let { letterId, email } = useParams();

  const step = useSelector(selectStep);
  const dispatch = useDispatch();

  useEffect(() => {
    if (letterId) {
      dispatch(changeStep(4));
      dispatch(getLetterInfoAsync({ letterId, email }));
    }
  }, []);
  let stepComponent;
  if (step === 0) {
    stepComponent = <HasPostcard />;
  }
  if (step === 1) {
    stepComponent = <LetterGenerator />;
  }
  if (step === 2) {
    stepComponent = <Preview />;
  }
  if (step === 3) {
    stepComponent = <ContactForm />;
  }
  if (step === 4) {
    stepComponent = <Payment />;
  }
  return (
    <div>
      {stepComponent}
      <div className="hidden">
        <button onClick={() => dispatch(changeStep(step + 1))}>
          {" "}
          Change step
        </button>
        <button onClick={() => dispatch(changeStep(2))}> contact form</button>
        <button onClick={() => dispatch(changeStep(3))}> payment</button>
      </div>
    </div>
  );
}
