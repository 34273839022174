import React from 'react';
import {Link} from "react-router-dom";
import {NumberList} from "./NumberList";
import MainTeaser from "../../layout/MainTeaser";

const HowToWrite = () => {
  return (
    <>
      <div className = "center-container narrow">
        <h1 className = "text-header green mb-8">Zrób prezent swoim bliskim. To naprawdę proste!</h1>
        <p className = "text-xl md:text-2xl font-bold mb-8">W tym roku nie musisz zastanawiać się nad wyjątkowym
          prezentem dla
          babci,<br className = "hidden md:block" /> dziadka lub innej bliskiej Ci osoby starszej. Zrobiliśmy to za
          Ciebie!</p>
      </div>
      <div className = "center-container pr-6 md:px-0">
        <div className = "md:grid md:grid-cols-3 md:gap-6">
          <div className = "how-to-write-left">

          </div>
          <div className = "col-span-2">
            <NumberList number = "1" header = "Wybierz i spersonalizuj prezent"> Do upominku <strong>„Siłownia Pamięci -
              ćwiczenia z głową”</strong> możesz dodać kartkę z osobistymi życzeniami dla bliskiej Ci osoby - napisz je
              samodzielnie lub skorzystaj z naszych wzorów.
            </NumberList>
            <NumberList number = "2" header = "Wypełnij formularz adresowy">
              Podaj dane korespondencyjne osoby, do której mamy wysłać prezent.
              To może być adres babci, dziadka albo Twój, jeśli planujesz wręczyć prezent osobiście.
            </NumberList>
            <NumberList number = "3" header = "Przekaż darowiznę i pomóż nam pomagać">
              <p className = "mb-6">Twoja darowizna pokryje koszt przygotowania, wydruku i przesyłki prezentu, ale
                przede wszystkim pomoże nam kontynuować program <strong>Siłownia Pamięci</strong> i wspierać seniorów w
                trosce o ich zdrowie, sprawną pamięć, komfort i bezpieczeństwo. </p>
              <p>
                <Link to = "/jak-pomaga-twoja-darowizna">Zobacz, jak Twój prezent pomaga nam<br /> wspierać osoby
                  starsze</Link>
              </p>
            </NumberList>
          </div>

        </div>
      </div>
      <div className="-mb-16">
        <MainTeaser />
      </div>
    </>

  );
};

export default HowToWrite;
