import React, {useState} from 'react';
import choose_1 from '../../../assets/images/stocznia/wybor_1.png';
import choose_2 from '../../../assets/images/stocznia/wybor_2.png';
import SubmitButton from "../contact_form/SubmitButton";
import {changeLetterPostCardSetup} from "../letterOrderSlice";
import {useDispatch} from "react-redux";

const HasPostcard = ({control, register, errors}) => {
  const [choose, setChoose] = useState(true);
  const dispatch = useDispatch();

  const handleChoose = (c) => {
    setChoose(c);
  };
  const handleSubmit = () => {
     dispatch(changeLetterPostCardSetup(choose))
  };
  return (
    <div className="center-container narrow mb-20 ">
      <h2 className = "bigger mt-10 mb-0 md:mb-0">Krok 1:</h2>
      <h2 className = "big mt-0 mb-4 md:mb-6 ">Wybierz i spersonalizuj prezent</h2>
      <p className = "mt-6 mb-6 font-bold text-xl md:text-3xl">Który prezent wybierasz?</p>
      <div className = "has-postcard flex gap-1 md:gap-8">
        <div className = "w-1/2 text-center">
          <div className ={`${choose ? "active" : ""} step h-60 md:h-80 cursor-pointer`} onClick={()=>handleChoose(true)}>
            <div className = "p-2 md:p-8">
              <img src = {choose_1} alt = "wybor" className = "block mx-auto h-full" />
            </div>
          </div>
          <p className = "mt-6 font-bold text-sm md:text-xl">"Siłownia Pamięci<br /> - ćwiczenia z głową" <br />+ kartka z
                                                  życzeniami </p>

        </div>
        <div className = "w-1/2 text-center">
          <div className ={`${!choose ? "active" : ""} step h-60 md:h-80 cursor-pointer`} onClick={()=>handleChoose(false)}>
            <div className = "p-2 md:p-8">
              <img src = {choose_2} alt = "wybor" className = "block mx-auto" />
            </div>
          </div>

          <p className = "mt-6 font-bold text-sm md:text-xl">"Siłownia Pamięci <br />- ćwiczenia z głową" </p>

        </div>
      </div>
      <div className="my-8" >
      <SubmitButton  onClick={handleSubmit}/>
      </div>
    </div>
  );
};

export default HasPostcard;