import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeCustomChoose, selectCustomChoose} from "../letterOrderSlice";


export const CustomChooser = () => {
  const [choose, setChoose] = useState(false);
  const customChoose = useSelector(selectCustomChoose);
  const dispatch = useDispatch();
  const handleChoose = (c) => {
    dispatch(changeCustomChoose(c))
    console.log({choose})
    setChoose(c);
  };
  useEffect(() => {
    setChoose(customChoose)
  }, [])

  return (
    <div>
      <div className = "center-container narrow mb-20 ">
        <p className = "mt-6 mb-6 font-bold text-xl md:text-3xl">Napisz życzenia</p>
        <div className = "flex gap-1 md:gap-8">
          <div className = "w-1/2 text-center">
            <div
              className = {`${choose === 1 ? "active" : ""} step h-60 md:h-80 cursor-pointer place-content-center flex`}
              onClick = {() => handleChoose(1)}>
              <div className = "font-bold text-xl md:text-3xl">
                Chcę skorzystać<br /> ze wzoru treści
              </div>
            </div>
          </div>
          <div className = "w-1/2 text-center">
            <div
              className = {`${choose === 2 ? "active" : ""} step h-60 md:h-80 cursor-pointer place-content-center flex`}
              onClick = {() => handleChoose(2)}>
              <div className = "font-bold text-xl md:text-3xl">
                Chcę samodzielnie<br /> napisać życzenia
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
}