import React from "react";
import {Link} from "react-router-dom";
import {selectEventConfig} from "../features/event_fetcher/EventFetcherSlice";
import {useSelector} from "react-redux";
import header_text from './../assets/images/stocznia/header_text.png'

function MainTeaser({event}) {
  const {letters_counter} = useSelector(selectEventConfig);
  return (
    <div className = "teaser">
      <div className = "center-container">
        <div className = "inside-text">
          <h2><img src = {header_text} alt = "header_text" /></h2>
          <h3>Na Dzień Babci i Dziadka <br />podaruj im <br /> najlepszy prezent!</h3>
          {event.active && (<p>
            <Link to = "/wyslij-prezent" className = "yellow-button yellow-bigger">
              Wyślij <strong>prezent</strong>
            </Link>
          </p>)}
        </div>
      </div>
    </div>


  );
}

export default MainTeaser;
