import React, {useState} from 'react';
import zeszyt from '../../assets/images/stocznia/zeszyt_cwiczenia_.png';
import kartki from '../../assets/images/stocznia/kartki.png';
import koperta from '../../assets/images/stocznia/koperta.png';
import koperta_zoom from '../../assets/images/stocznia/koperta_2.png';
import {ModalZoom} from "./ModalZoom";
import zeszyt_zoom from "../../assets/images/stocznia/zeszyt_powiekszenie.png";
import kartka_one_zoom from "../../assets/images/stocznia/gift.png";
import kartka_two_zoom from "../../assets/images/stocznia/gift podglad.png";
import {Hamburger} from "./Hamburger";

const ThreeCircles = props => {
  const [isModal, setIsModal] = useState(false);

  const showModal = (modal) => {
    setIsModal(modal);
  };
  const hideModal = () => {
    setIsModal(false)
  };


  return (
    <div>

      <ModalZoom show = {isModal} hideModal = {hideModal}>
        {isModal === 1 && <div className="max-h-screen">
          <img src = {zeszyt_zoom} alt = "zeszyt powiększenie" className = "md:w-3/4 mx-auto max-h-screen" />
          <div className = "text-right pr-16 relative top-0 lg:-top-20 "><strong className = "uppercase">Zeszyt ćwiczeń</strong>
            <br /> format A6
          </div>
        </div>}
        {isModal === 2 && <div>
          <img src = {kartka_one_zoom} alt = "kartka one powiększenie" className = "w-100  max-h-screen" />
          <div className = "text-right pr-16 md:-mt-16"><strong className = "uppercase">Kartka z życzeniami</strong>
            <br /> format A6
          </div>
        </div>}
        {isModal === 3 && <div>
          <img src = {kartka_two_zoom} alt = "kartka one powiększenie" className = "w-100  max-h-screen" />
          <div className = "text-right pr-16 md:-mt-16"><strong className = "uppercase">Kartka z życzeniami</strong>
            <br /> format A6
          </div>
        </div>
        }
        {isModal === 4 && <div>
          <img src = {koperta_zoom} alt = "kartka one powiększenie" className = "w-3/4 mx-auto max-h-screen" />
          <div className = "text-right pr-16 relative top-0 lg:top-10"><strong className = "uppercase">Koperta na prezent</strong>
            <br /> format A6
          </div>
        </div>
        }
      </ModalZoom>

      <div className = "text-center font-bold relative mb-8 md:mb-0 -top-16 lg:top-12 md:text-xl">Kliknij w obrazek i zobacz więcej</div>
      <div className = "flex justify-between flex-nowrap flex-col lg:flex-row">
        <div className = "circle-brief-new">
          <div className = "text-center absolute bottom-14">
            <div className = "image">
              <img src = {zeszyt} alt = "zeszyt" onClick = {() => showModal(1)} className = "cursor-pointer" />
            </div>
            <div className = "text-xl">
              <strong>16 stronicowy zeszyt</strong><br />
              z ćwiczeniami
            </div>
            <div className = "text-sm">format A4</div>
          </div>
        </div>
        <div className = "circle-brief-new">
          <div className = "text-center absolute bottom-14">
            <div className = "image">
              <img src = {kartki} alt = "kartki" useMap = "#kartki_map" />
              <map name = "kartki_map">
                <area shape = "rect" coords = "0,0,184,260" href = "#a" alt = "kartki" onClick = {() => showModal(2)} />
                <area shape = "rect" coords = "184,0,367,260" href = "#b" alt = "kartki"
                      onClick = {() => showModal(3)} />
              </map>
            </div>
            <div className = "text-xl">
              <strong>Do wyboru dwie grafiki</strong><br />
              na kartce z życzeniami
            </div>
            <div className = "text-sm">format A5</div>
          </div>
        </div>
        <div className = "circle-brief-new">
          <div className = "text-center absolute bottom-14">
            <div className = "image md:left-4">
              <img src = {koperta} alt = "koperta" onClick = {() => showModal(4)} className = "cursor-pointer" />
            </div>
            <div className = "text-xl">
              <strong>Koperta</strong><br />
              w niej będzie
            </div>
            <div className = "text-xl">wysłany prezent</div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ThreeCircles;