import React, {useEffect} from "react";

import {Controller, useWatch} from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";

const LetterFieldMultiSelect = ({
                                  letterField,
                                  control,
                                  register,
                                  errors,
                                  setValue,
                                  touchedFields,
                                  unregister,
                                }) => {
  const formWatch = useWatch({
    control,
  });

  const conditional_field =
    letterField.conditional_letter_field &&
    letterField.conditional_letter_field;

  const conditional_field_change =
    conditional_field &&
    formWatch &&
    formWatch["field_" + conditional_field.id];

  const currentFieldWatch = formWatch["field_" + letterField.id];
  const openField = currentFieldWatch && currentFieldWatch.find(x => x.open)
  const conditionalWasTouched = conditional_field
    ? touchedFields["field_" + conditional_field.id]
    : null;

  useEffect(() => {
    if (
      conditionalWasTouched

      // pole ma być wyczyszczone kiedy zmienia się conditional ale tylko wtedy kiedy robione jest to recznie
    ) {
      setValue("field_" + letterField.id, null);
    }
  }, [conditional_field_change, letterField, setValue, conditionalWasTouched]);

  const options = [];

  letterField.letter_field_options.forEach((fieldOption) => {
    const hastagregex = /#\S*/gm;
    const hasInsideReplacer = hastagregex.test(fieldOption.label);
    fieldOption = {
      ...fieldOption,
      label: fieldOption.label.replace(hastagregex, "______"),
      hasInsideReplacer: hasInsideReplacer,
    };

    if (!fieldOption.conditional_letter_field_option) {
      // if option is not conditional just add
      options.push({
        value: fieldOption.id,
        label: fieldOption.label,
        open: fieldOption.is_open,
        display: fieldOption.value,
        hasInsideReplacer: fieldOption.hasInsideReplacer,
      });
    } else {
      // option is conditional
      if (
        (formWatch["field_" + conditional_field.id] &&
          formWatch["field_" + conditional_field.id].value) ===
        fieldOption.conditional_letter_field_option.id // conditional select has conditional option selected
      ) {
        options.push({
          value: fieldOption.id,
          label: fieldOption.label,
          open: fieldOption.is_open,
          display: fieldOption.value,
          hasInsideReplacer: fieldOption.hasInsideReplacer,
        });
      }
    }
  });
  useEffect(() => {
    if (currentFieldWatch && !openField) {
      unregister("multi_o__" + letterField.id);
    }
  }, [currentFieldWatch]);
  if (conditional_field) {
    if (!formWatch["field_" + conditional_field.id]) {
      return <></>;
    }
  }


  return (
    <div className = "form-group">
      <label
        className = {classNames({
          error: errors["field_" + letterField.id],
        }, 'form-generator-label')}
      >
        {letterField.label}

        <small  className="error-info"> (od 1 do 3 elementów)</small>
      </label>
      <Controller
        name = {"field_" + letterField.id}
        control = {control}
        render = {({field}) => (
          <Select
            {...field}
            isMulti
            options = {options}
            placeholder = ""
            isSearchable = {false}
            isClearable={false}
            blurInputOnSelect = {true}
            className = "react-select-container" classNamePrefix = "react-select"
            theme = {(theme) => ({
              ...theme,
              colors: {...theme.colors, primary25: 'neutral50', primary: '#00AD9D'},
            })}
          />
        )}
        rules = {{required: true, validate: value => Array.isArray(value) && value.length <= 3}}
      />

      {currentFieldWatch && openField && (
        <div className = "mt-4">
          <div
            className = {classNames({
              error: errors["multi_o__" + letterField.id],
            })}
          >
           {errors && errors["multi_o__" + letterField.id] && (<small>{errors["multi_o__" + letterField.id].message}</small>)}
            <input
              className = "w-full py-4 px-3 border border-gray-400 rounded"
              placeholder = "Wpisz samodzielnie..."
              {...register("multi_o__" + letterField.id, {
                required: true,
                maxLength: {value: 50, message: "Maksymalnie 50 znaków"},
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LetterFieldMultiSelect;
