import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import LivePreview from "./../letter_generator/LivePreview";
import {changeStep, selectCustomChoose, selectLetterContent, selectStep,} from "../letterOrderSlice";
import {selectEventConfig,} from "../../event_fetcher/EventFetcherSlice";
import parse from "html-react-parser";

const Preview = () => {
  const dispatch = useDispatch();
  const letterContent = useSelector(selectLetterContent);
  const {letter_types, backgrounds} = useSelector(selectEventConfig);
  const step = useSelector(selectStep);
  const {watch, control} = useForm({defaultValues: letterContent});
  const letterType = watch("letter_type");
  const background_id = watch("background_id");
  // console.log(background_id, backgrounds);
  const selectedBackground = backgrounds.find((f) => f.id === background_id);
  const customChoose = useSelector(selectCustomChoose);
  console.log(selectedBackground);
  const selectedLetterType = letterType
    ? letter_types.find((element) => element.id === letterType.value)
    : null;
  return (
    <div className = "center-container narrow">
      <div className = "p-0 py-4  md:p-8 md:mx-12">
        <div className = "center-container narrow-2">
          <h2 className = "text-xl  px-1 md:text-3xl font-bold mb-5 md:mb-8">Sprawdź kartkę z życzeniami</h2>
          <div className="mb-4">
            <img
              src={selectedBackground.thumbnail_url}
              alt="Kartka"
              width="176"
              className="border border-gray-10 shadow"
            />
          </div>
          <div className = "custom-preview p-5 md:p-12 border shadow-xl border-gray-10">
            {customChoose === 1 && (
              <LivePreview letter_type = {selectedLetterType} control = {control} />
            )}
            {customChoose === 2 && (
              <div>
                {parse(letterContent.custom_full_letter.replace(/\n/g, "<br />"))}
              </div>

            )}
          </div>
        </div>
      </div>
      <div className = "flex gap-6 justify-center">
        <div>
          <button
            onClick = {() => dispatch(changeStep(step - 1))}
            className = "yellow-button"
          >
            <strong> Popraw</strong>
          </button>
        </div>
        <div>
          <button
            onClick = {() => dispatch(changeStep(step + 1))}
            className = "yellow-button"
          >
            <strong>Zaakceptuj</strong>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preview;
