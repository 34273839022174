import React from "react";
import classNames from "classnames";
import { get } from "../../../tools/get";

const EmailField = ({ name, label, register, errors, autoComplete, otherClasses }) => {
  return (
      <div className="flex flex-col md:flex-row md:gap-0 md:items-center">
        <label
            className={classNames({
              error: get(errors, name),
            },  "contact-input-label")}
        >
          {label}
      </label>
      <input
        type="email"
        className={otherClasses || 'contact-input'}
        autoComplete={autoComplete ? autoComplete : ""}
        {...register(name, {
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "Wpisz poprawny adres e-mail",
          },
        })}
      />
      {errors[name] && <p className="error">{errors[name].message}</p>}
    </div>
  );
};

export default EmailField;
