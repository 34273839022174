import React, {useEffect} from "react";
import MenuTop from "./MenuTop";

import MainTeaser from "./MainTeaser";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import {useLocation} from "react-router-dom";
import {backdoorActivateEvent, selectEventConfig} from "../features/event_fetcher/EventFetcherSlice";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import logo from "../assets/images/stocznia/logo_stocznia.png";
import logo_silownia from "../assets/images/stocznia/logo_silownia.png";

const Layout = ({children}) => {
  const dispatch = useDispatch();
  const {event} = useSelector(selectEventConfig);

  const location = useLocation();
  const mainPagePath =
    (location.pathname === "/" ||
      location.pathname.includes("backdoor") ||
      location.pathname.includes("kupon") ||
      location.pathname.includes("partner")) ;

  useEffect(() => {
    location.pathname.includes("backdoor") && dispatch(backdoorActivateEvent())
    location.pathname.includes("partner") && dispatch(backdoorActivateEvent())
    location.pathname.includes("kupon") && dispatch(backdoorActivateEvent())
  }, [])

  return (
    <>
      <ScrollToTop />
      <div
        className = {classNames("container-fluid", "header-container", {
          "header-container": mainPagePath,
          "header-small-container": !mainPagePath,
        })}
      >
        <div>
          <MenuTop />

          {mainPagePath && <MainTeaser event="event" />}
        </div>
      </div>
      {!event.active && location.pathname === "/" && (
        <div className = "center-container narrow-2 mt-10">

          <div className = "md:flex gap-20">
            <div className = "flex-none md:w-1/5 mb-5 mt-5 hidden md:block">
              <img src = {event.cap_image} alt = "Dziękujemy"  className=" md:w-full transform -rotate-12"/>
            </div>
            <div className = "flex-grow text-xl">
              <div
                dangerouslySetInnerHTML = {{
                  __html: event.cap,
                }}
              />
            </div>
          </div>

        </div>
      )}
      <div className = "border-bottom-with-shadow py-8 md:py-8">
        {children}
      </div>
      <Footer event={event}/>
    </>
  );
};

export default Layout;
