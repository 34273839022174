import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectEventConfig} from "../../event_fetcher/EventFetcherSlice";
import {useParams} from "react-router-dom";
import {
  saveLetterAsync,
  selectContactForm,
  selectCoupon,
  selectIpAddress,
  selectIsPaid,
  selectLetterContent,
  selectLetterId,
  selectPartner,
  selectSessionId,
  selectTimestamp,
} from "../letterOrderSlice";
import PaymentChooser from "./PaymentChooser";
import PaymentInfo from "./PaymentInfo";

const Payment = () => {
  const dispatch = useDispatch();
  const letterContent = useSelector(selectLetterContent);
  const contactForm = useSelector(selectContactForm);
  const coupon = useSelector(selectCoupon);
  const partner = useSelector(selectPartner);
  const letterId = useSelector(selectLetterId);
  const sessionId = useSelector(selectSessionId);
  const ipAddress = useSelector(selectIpAddress);
  const timestamp = useSelector(selectTimestamp);
  const isPaid = useSelector(selectIsPaid);

  const {event} = useSelector(selectEventConfig);
  let params = useParams();
  useEffect(() => {
    !params.letterId &&
    dispatch(
      saveLetterAsync({
        fields: {...letterContent},
        ...contactForm,
        coupon: coupon,
        partner: partner,
        event_id: event && event.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let paymentForm;
  if (letterId) {
    paymentForm = (
      <PaymentChooser sender = {contactForm.sender}
        letterId = {letterId}
        sessionId = {sessionId}
        timestamp = {timestamp}
        ipAddress = {ipAddress} />
    );
  } else {
    paymentForm = (
      <div className = "center-container narrow-2 mb-10">
        <h3 className = "text-xl text-green p-8">Zapisywanie...</h3>
      </div>
    );
  }
  if (coupon && coupon.free) {
    paymentForm = "";
  }
  if (partner && partner.free) {
    paymentForm = "";
  }

  if (isPaid) {
    paymentForm = (
      <div className = "center-container narrow-2"><h1 className = "text-2xl">Twój prezent został już opłacony.</h1>
      </div>);
  }
  return (
    <div>
      <PaymentInfo coupon = {coupon} partner = {partner} />
      {paymentForm}
    </div>
  );
};

export default Payment;
