import close_x from "../../assets/images/stocznia/x.png";
import React, {useEffect, useState} from "react";

export const ModalZoom = ({children, show, hideModal}) => {

  const [isShow, setIsShow] = useState(true)
  const handleClose = () => {
    setIsShow(false)
    hideModal()
  }
  useEffect(() => {
    setIsShow(show)
  }, [show])

  if (!isShow) {
    return null
  }
  return (

    <div
      className = "fixed top-0 left-0 right-0 w-full h-full z-50 bg-gray-dark bg-opacity-80 flex justify-center items-center"
      onClick = {handleClose}>
      <div className = "p-3 md:px-40 max-h-screen">
        <img src = {close_x} alt = "close" className = "w-5 h-5 cursor-pointer" onClick = {handleClose}
             align = "right" />
        {children}
      </div>
    </div>
  );
}