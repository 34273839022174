import React, {useEffect} from "react";
import classNames from "classnames";

import {useWatch} from "react-hook-form";

const LetterFieldInput = ({letterField, control, register, errors, setValue, touchedFields,}) => {
  const formWatch = useWatch({control,});
  const conditional_field = letterField.conditional_letter_field;
  const conditional_letter_field_option = letterField.conditional_letter_field_option;
  console.log({conditional_letter_field_option});

  const conditional_field_change = conditional_field && formWatch && formWatch["field_" + conditional_field.id];
  const conditionalWasTouched = conditional_field ? touchedFields["field_" + conditional_field.id] : null;

  useEffect(() => {
    if (conditionalWasTouched) {  // pole ma być wyczyszczone kiedy zmienia się conditional ale tylko wtedy kiedy robione jest to recznie
      setValue("field_" + letterField.id, null);
    }
  }, [conditionalWasTouched]);

  if (conditional_field) {
    if (!formWatch["field_" + conditional_field.id]) {
      return <></>;
    }
  }
  if (
    conditional_field_change &&
    conditional_field_change.value !== conditional_letter_field_option.id
  ) {
    return <></>;
  }

  // console.log(letterField);

  return (
    <div className = "form-group">
      <label
        className = {classNames({
          error: errors["field_" + letterField.id],
        }, 'form-generator-label')}
      >
        {letterField.label}
      </label>

      <input
        className = "w-full py-4 px-3 border border-gray-400 rounded"
        {...register("field_" + letterField.id, {
          required: true,
        })}
      />
    </div>
  );
};

export default LetterFieldInput;
