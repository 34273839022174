import React from "react";
import classNames from "classnames";

const PaymentAmount = ({
                         children,
                         amountVal,
                         selectedAmount,
                         handleClick,
                         handleOther,
                         minimum_amount,
                       }) => {
  if (amountVal) {
    return (
      <>
        <div
          onClick = {handleClick}
          className = {classNames("step h-24 md:h-24 cursor-pointer place-content-center flex", {
            active: amountVal.toString() === selectedAmount,
          })}
        >
          <div className = "font-bold text-xl md:text-3xl">
            {amountVal} zł
          </div>
        </div>
        <div className = "dot-info hidden-xs">{children}</div>
      </>
    );
  } else {
    return (
      <>
        <div
          onClick = {handleClick}
          className = {classNames(["dot-chooser", "step h-24 md:h-24 cursor-pointer place-content-center flex"], {
            active: selectedAmount === "other",
          })}
        >
          {/* <div className="inna_label">Inna kwota</div> */}
          <div className="px-8">
          <input
            type = "number"
            min = {minimum_amount}
            step = "5"
            className = "placeholder-gray-500 font-bold text-xl md:text-3xl w-full text-center focus:outline-none"
            placeholder = "Wpisz kwotę"
            onChange = {handleOther}
          />
          </div>
        </div>
        <div className = "dot-info hidden-xs">{children}</div>
      </>
    );
  }
};

export default PaymentAmount;
