import React from "react";

export const NumberList = ({number, header, children}) => {
  return (
    <div className = "flex mb-12">
      <div className="text-6xl md:text-8xl font-bold text-green-10 px-6">
        {number}
      </div>
      <div className = "col-span-2">
        <h2 className="text-xl md:text-3xl font-bold text-green-light">{header}</h2>
        <div className="md:w-4/5 text-base md:text-xl">
          {children}
        </div>
      </div>
    </div>
  )
};

