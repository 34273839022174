import React from "react";
import {Link} from "react-router-dom";

export const MaterialInfo = () => <div className = "md:grid md:grid-cols-2 md:gap-4">
  <div className = "md:mr-40 bg-green-100 flex justify-end green-left">
    <div className = "left-common py-8 md:pr-5 pt-80">
      <h1 className = "text-green text-header mb-4 leading-6">
        W naszym materiale <br /> znajdują się:</h1>
      <ul className = "md:text-xl square-list more-space text-green">
        <li>informacje, dlaczego warto trenować umysł,</li>
        <li>zadania, których wykonanie stymuluje różne funkcje poznawcze, m.in. pamięć, logiczne myślenie, orientację
          wzrokowo-przestrzenną, koncentrację i planowanie,
        </li>
        <li>poprawne odpowiedzi, do których warto zajrzeć po wykonaniu zadań,
        </li>
        <li>praktyczne ćwiczenia, które można wykonywać każdego dnia.

        </li>
      </ul>
    </div>
  </div>
  <div className = "md:-ml-40 md:pl-10 pt-8">
    <div className = "common-text">
      <p>Zamawiając prezent, nie tylko obdarujesz swoich bliskich, lecz także - dzięki wpłacie darowizny - pomożesz nam
        rozwijać Siłownię Pamięci, upowszechniać treningi umysłu i wspierać osoby starsze w dbaniu o ich zdrowie,
        komfort życia i bezpieczeństwo.
      </p>
      <p>
        <strong className = "underline">
          <Link to = "/jak-pomaga-twoja-darowizna" className = "text-gray-dark">Zobacz, jak Twoja darowizna pomaga nam
            wspierać osoby starsze!</Link></strong>
      </p>
      <h2 className = "text-header green mb-8">Dlaczego ćwiczenia <br />umysłu są ważne? </h2>
      <p>Badania dowodzą, że systematyczne ćwiczenia umysłu są doskonałą profilaktyką zdrowia – stymulują mózg do
        wysiłku, przez co wytwarzają się nowe połączenia neuronalne i dłużej zachowuje on sprawność i gotowość do
        podjęcia nowych wyzwań.</p>
      <p>Każdy z nas ma do swojej dyspozycji aż 100 miliardów komórek nerwowych, które tworzą skomplikowane struktury
        neuronalne. Niestety, czas nie jest naszym sprzymierzeńcem. Wraz z upływem lat zdolność neuronów do
        przekazywania i przechowywania informacji ulega stopniowemu pogorszeniu. Z pomocą przychodzą tu treningi
        umysłu!</p>
      <p>W tego rodzaju ćwiczeniach bardzo ważna jest systematyczność oraz różnorodność wykonywanych ćwiczeń. Mózg nie
        lubi rutyny!</p>
      <p>Należy mu dostarczać nowych wyzwań – wykonywać ćwiczenia pamięciowe, językowe, matematyczne i manualne oraz
        utrzymywać relacje społeczne i otwierać się na nowe doświadczenia.</p>
    </div>
  </div>
</div>;