import React from "react";
import {Link} from "react-router-dom";

function Footer({event}) {
  return (
    <>

      <div className = "center-container narrow-2  md:flex justify-between mt-8">
        <div className = "md:py-20">
          <strong>Fundacja Stocznia</strong> <br />
          pl. Zamkowy 10 <br />
          00-277 Warszawa <br />
          <a href = "https://stoczna.org.pl">www.stocznia.org.pl</a> <br />
          KRS: 0000324413 <br /> <br />
          tel. (+48) 22 378 38 94 <br />
          e-mail: <a href = "mailto:silowniapamieci@stocznia.org.pl">silowniapamieci@stocznia.org.pl</a>
        </div>
        {event.active && (
          <div className = "py-10 md:py-20">
            <a href = "https://stocznia.org.pl/statut/" target = "_blank" rel = "noreferrer"> Statut </a>
            | <a href = "https://stocznia.org.pl/sprawozdania/"
            target = "_blank"
            rel = "noreferrer">Sprawozdania</a> | <Link
            to = "/polityka-prywatnosci">Polityka prywatności</Link> | <Link to = "/regulamin"> Regulamin
            strony</Link> | <Link to = "/regulamin-darowizny"> Regulamin darowizny</Link>

          </div>
          )}
      </div>
    </>

  );
}

export default Footer;
