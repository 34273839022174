import React from "react";
import './headerIcons.scss';

const HeaderIcons = ({step}) => {

  return (
    <div className = "border-bottom-with-shadow">
      <div className = "pb-12 center-container narrow ">
        <h2 className = "big uppercase mb-4 md:mb-8">Wyślij prezent</h2>
        <div className = "flex gap-1 md:gap-8">
          <div className = {`${step === 1 || step === 0 ? "active" : ""} md:flex step w-1/3`}>
            <h2>1.</h2>
            <p>Wybierz <br /> i spersonalizuj <br />prezent</p>
          </div>
          <div className = {`${step === 2 ? "active" : ""} md:flex step w-1/3`}>
            <h2>2.</h2>
            <p>Wypełnij <br /> formularz <br />adresowy</p>
          </div>
          <div className =  {`${step === 3 ? "active" : ""} md:flex step w-1/3`}>
            <h2>3.</h2>
            <p>Przekaż <br /> darowiznę <br />i pomóż nam<br /> pomagać </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderIcons;
