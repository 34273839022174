import React from "react";
import classNames from "classnames";
import parse from "html-react-parser";
import {get} from "../../../tools/get";

const InputLabel = ({
                      label, placeholder, name, register, errors, autoComplete,
                      children, otherClasses, required, pattern, error_message
                    }) => {
  const currentError = get(errors, name)

  return (

    <div className = "flex flex-col md:flex-row md:gap-0 md:items-center">
      <label
        className = {classNames({
          error: currentError,
        }, "contact-input-label")}
      >

        {parse(label)}
      </label>
      <div className = "relative">
        <input
          type = "text"
          className = {otherClasses || 'contact-input'}
          placeholder = {placeholder}
          autoComplete = {autoComplete ? autoComplete : ""}
          {...register(name, {
            required: required,
            pattern: pattern && {
              value: pattern,
              message: error_message,
            },
          })}
        />

        {children}
        {currentError && currentError.message && (
          <div className = "absolute -bottom-6 left-0"><small><br />{currentError.message}</small></div>)}
      </div>
    </div>


  );
};

export default InputLabel;
